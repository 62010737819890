<template>
    <div class="max-w-4xl min-h-screen mx-auto mt-8 bg-white shadow-md rounded-lg overflow-hidden">
      <div class="p-6">
      <!-- Back Button -->
      <button
        @click="goBack"
        class="mb-4 flex items-center text-blue-600 hover:text-blue-800 transition duration-300"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
        </svg>
        Back to Posts
      </button>
      </div>
      <div v-if="post" class="p-6">
        <div class="flex items-center space-x-3 mb-4">
          <div>
            <h3 class="text-lg font-semibold">{{ post.author.username }}</h3>
            <p class="text-sm text-gray-500">{{ formatDate(post.created_at) }}</p>
          </div>
        </div>
        <h2 class="text-3xl font-bold mb-4">{{ post.title }}</h2>
        <p class="text-gray-700 mb-6">{{ post.content }}</p>
        <div class="flex items-center space-x-4 mb-6">
          <button
            @click="upvotePost"
            class="flex items-center text-gray-500 hover:text-blue-600 transition duration-300"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-1" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
            </svg>
            {{ post.upvotes_count }}
          </button>
          <span class="text-gray-500">
            {{ post.comments_count }} comments
          </span>
        </div>
  
        <!-- Comments Section -->
        <div class="border-t pt-6">
          <h3 class="text-xl font-semibold mb-4">Comments</h3>
          <div v-if="post.comments && post.comments.length > 0" class="space-y-4">
            <div v-for="comment in post.comments" :key="comment.id" class="bg-gray-50 p-4 rounded-lg">
              <div class="flex justify-between items-start">
                <div>
                  <p class="font-semibold">{{ comment.author.username }}</p>
                  <p class="text-sm text-gray-500">{{ formatDate(comment.created_at) }}</p>
                </div>
              </div>
              <p class="mt-2">{{ comment.content }}</p>
            </div>
          </div>
          <div v-else class="text-gray-500">No comments yet.</div>
        </div>
  
        <!-- Add Comment Form -->
        <div v-if="authenticated" class="mt-6">
          <h4 class="text-lg font-semibold mb-2">Add a comment</h4>
          <textarea
            v-model="newComment"
            placeholder="What are your thoughts?"
            class="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            rows="3"
          ></textarea>
          <button
            @click="submitComment"
            class="mt-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 font-medium"
          >
            Post Comment
          </button>
        </div>
      </div>
      <div v-else class="p-6 text-center text-gray-500">
        Loading post...
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import { useRouter } from 'vue-router';
  import axios from 'axios';
const router = useRouter();
  const store = useStore();
  const route = useRoute();
  const authenticated = computed(() => store.state.authenticated);
  
  const post = ref(null);
  const newComment = ref('');
  
  onMounted(async () => {
    await fetchPost();
  });
  
  const fetchPost = async () => {
    try {
      const postId = route.params.id;
      const response = await axios.get(`/api/community/posts/${postId}/`);

      post.value = response.data;
    } catch (error) {
      console.error('Error fetching post:', error);
    }
  };
  
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };
  
  const upvotePost = async () => {
    try {
      await axios.post(`/api/community/posts/${post.value.id}/upvote/`);
      await fetchPost(); // Refresh the post data
    } catch (error) {
      console.error('Error upvoting post:', error);
    }
  };
  
  const submitComment = async () => {
    try {
      await axios.post(`/api/community/posts/${post.value.id}/comments/`, {
        content: newComment.value
      });
      newComment.value = ''; // Clear the comment input
      await fetchPost(); // Refresh the post data to include the new comment
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  };
  const goBack = () => {
  router.go(-1); // This will navigate to the previous page in the browser history
};  
  </script>