<!-- Pantry.vue -->
<template>
    <div class="no-scrollbar flex flex-col h-screen bg-white border-l-1 border-gray-700 drop-shadow-lg overflow-y-auto w-full">
      <div class="inventory-header sticky top-0 z-10 bg-white p-2">
        <div class="px-4 py-2">
          <div class="flex justify-between w-full items-center mb-2">
            <h3 class="font-medium text-xl text-gray-800 font-main">Inventory</h3>
            <!-- Clear List Button -->
            <button 
              @click="clearList"
              class="bg-red-500 text-white text-sm px-3 py-1 rounded hover:bg-red-600 font-main"
            >
              Clear {{ inventoryType.charAt(0).toUpperCase() + inventoryType.slice(1) }}
            </button>
          </div>
        </div>
      
        <!-- Tab-like headers for switching between Pantry, Freezer, and Perishables -->
        <div class="flex justify-center space-x-4 border-b">
          <button 
            v-for="type in ['pantry', 'perishables', 'freezer']"
            :key="type"
            @click="inventoryType = type"
            class="px-4 py-2 focus:outline-none text-xs md:text-sm"
            :class="{'text-blue-600 border-b-2 border-blue-600': inventoryType === type, 'text-gray-500 hover:text-gray-700': inventoryType !== type}"
          >
            {{ type.charAt(0).toUpperCase() + type.slice(1) }}
          </button>
        </div>
  
        <!-- Add Item Button (moved under the tabs) -->
        <div v-if="!isLoading" class="px-2 py-2">
          <button 
            @click="addItem"
            class="w-full px-4 py-1 text-white bg-interactive rounded-full transition duration-300 ease-in-out font-main text-sm"
          >
            Add Item
          </button>
        </div>
      </div>
  
      <!-- Inventory Items List -->
    <div class="flex flex-col overflow-y-auto">
      <div class="">
        <div v-if="!isLoading && currentInventoryItems.length">
          <div v-for="(item, index) in currentInventoryItems" :key="index" 
              class="border border-y-1 border-x-0 border-gray-200 py-1 px-2 hover:shadow-lg">
            <div v-if="!item.selected">
              <input 
                v-model="item.name" 
                @input="filterIngredients(index)"
                placeholder="Search ingredient"
                class="w-full px-3 py-1 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 font-main text-sm"
              >
              <ul v-if="item.suggestions.length" class="mt-1 border border-gray-300 rounded-md shadow-sm max-h-40 overflow-y-auto">
                <li 
                  v-for="suggestion in item.suggestions" 
                  :key="suggestion.id" 
                  @click="selectIngredient(index, suggestion)"
                  class="px-3 py-1 hover:bg-gray-100 cursor-pointer font-main text-sm flex items-center"
                >
                  <div class="w-8 h-8 mr-2 flex-shrink-0">
                    <img v-if="suggestion.thumbnail" :src="suggestion.thumbnail" alt="Ingredient thumbnail" class="w-full h-full object-cover rounded-md">
                    <div v-else class="w-full h-full bg-gray-200 rounded-md"></div>
                  </div>
                  {{ suggestion.name }}
                </li>
              </ul>
            </div>
            
            <div v-else class="flex items-center">
              <div class="w-12 h-12 mr-2 flex-shrink-0">
                <img v-if="item.selected && item.selected.thumbnail" :src="item.selected.thumbnail" alt="Ingredient thumbnail" class="w-full h-full object-cover rounded-md">
                <div v-else class="w-full h-full bg-gray-200 rounded-md"></div>
              </div>
              <div class="flex-grow">
                <div class="w-full flex justify-between items-center">
                  <span class="font-semibold text-xs text-gray-800 font-main">{{ item.name }}</span>
                  <button @click="removeItem(index)" 
                          class="text-red-600 hover:text-red-500 transition duration-300 ease-in-out font-main">
                    <ion-icon name="close-outline"></ion-icon>
                  </button>
                </div>
              
                <div class="flex justify-between items-center">
                  <div class="w-1/2 pr-1">
                    <input 
                      v-model.number="item.amount" 
                      @input="updateIngredient(index)"
                      type="number" 
                      placeholder="Amount" 
                      class="w-full px-2 py-1 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 font-main text-sm"
                    >
                  </div>
                  
                  <div class="w-1/2 pl-1">
                    <select 
                      v-if="item.measurements.length > 1"
                      v-model="item.measurement" 
                      @change="updateIngredient(index)"
                      class="w-full px-2 py-1 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 font-main text-sm"
                    >
                      <option v-for="measurement in item.measurements" 
                              :key="measurement.id" 
                              :value="measurement">
                        {{ measurement.name }}
                      </option>
                    </select>
                    <span v-else class="block w-full px-2 py-1 font-semibold text-gray-700 font-main text-sm">{{ item.measurements[0].name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="isLoading">
          <div v-for="n in 25" :key="n" 
              class="border border-y-1 border-x-0 border-gray-200 py-2 px-2 hover:shadow-lg animate-pulse flex items-center">
            <div class="w-12 h-12 bg-gray-200 rounded-md mr-2 flex-shrink-0"></div>
            <div class="flex-grow">
              <div class="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
              <div class="h-4 bg-gray-200 rounded w-1/2"></div>
            </div>
          </div>
        </div>
        <div v-else class="text-center text-gray-500 py-4">
          No items in this inventory. Click "Add Item" to get started.
        </div>
        </div>
      </div>
      <!-- Pantry Toggle Button (visible only on mobile) -->
    <button 
      @click="togglePantryOnMobile()"
      class="fixed bottom-4 right-4 md:hidden z-50 bg-pink-500 text-white rounded-full p-4 shadow-lg"
      :class="{ 'opacity-50': !isPremiumUser }"
    >
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
      </svg>
    </button>
    </div>
  </template>
  
  <script setup>
import { ref, computed, onMounted  } from 'vue';
import axios from 'axios';

import { useStore } from 'vuex';
const store = useStore();
  const props = defineProps({
    showPantryOnMobile: Boolean,
  });
  
  const emit = defineEmits(['add:item', 'remove:item', 'clear:list', 'update:pantryItems', 'update:perishableItems', 'update:freezerItems', 'toggle-pantry']);
const isPremiumUser = ref(true);
const selectedIngredients = ref([{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }])
const pantryItems = ref([{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }]);
const perishableItems = ref([{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }]);  
const freezerItems = ref([{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }]);  
const allIngredients = ref([]);
const inventoryType = ref('pantry');
const isLoading = ref(true);
  const currentInventoryItems = computed(() => 
    inventoryType.value === 'pantry' ? pantryItems.value :
    inventoryType.value === 'perishables' ? perishableItems.value :
    freezerItems.value
  );
  
  const addItem = () => {
  const newItem = { name: '', suggestions: [], selected: false, measurement: null, measurements: [], amount: null };
  if (inventoryType.value === 'pantry') {
    pantryItems.value.unshift(newItem);
  } else if (inventoryType.value === 'freezer') {
    freezerItems.value.unshift(newItem);
  } else {
    perishableItems.value.unshift(newItem);
  }
};
const addI = async (listType, item) => {
  const list = getListByType(listType);
  if (item.selected) {
    const measurement = item.measurement || item.measurements[0]; 
    const amount = item.amount || 1;
    await updateList(item.selected.id, measurement.id, amount, listType, 'add');
  }
};
const getListByType = (listType) => {
  switch (listType) {
    case 'shopping':
      return selectedIngredients;
    case 'pantry':
      return pantryItems;
    case 'perishables':
      return perishableItems;
    case 'freezer':
      return freezerItems;
    default:
      throw new Error(`Invalid list type: ${listType}`);
  }
};
const removeI = async (listType, index) => {
  const list = getListByType(listType);
  const item = list.value[index];
  
  try {
    await updateList(item.selected.id, item.measurement.id, item.amount, listType, 'remove');
    list.value.splice(index, 1);
    // If the list is empty after removal, add an empty item
    if (list.value.length === 0) {
          list.value.push({ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null });
    }
    } catch (error ) {
    console.error('Error removing item:', error);
    }
};
const updateList = async (ingredientId, measurementId, amount, listType, action) => {
  try {
    updateCache();
    await axios.post('/api/ingredients/update_list/', {
      ingredient_id: ingredientId,
      measurement_id: measurementId,
      amount,
      list_type: listType,
      action
    });
  } catch (error) {
    console.error('Error updating list:', error);
  }
};
const togglePantryOnMobile = () => {
  window.scrollTo(0, 0);
  emit('toggle-pantry');
};
const selectIngredient = (index, suggestion) => {
  const newItem = {
    selected: suggestion,
    name: suggestion.name,
    amount: 1,
    measurement: suggestion.measurements[0],
    measurements: suggestion.measurements,
  };
  currentInventoryItems.value[index] = newItem;
  addI(inventoryType.value, newItem);
  updateCache();
};
  
const updateIngredient = (index) => {
  const updatedItems = [...currentInventoryItems.value];
  emit(`update:${inventoryType.value}Items`, updatedItems);
  addI(inventoryType.value, updatedItems[index]);
  updateCache();
};
  
const filterIngredients = (index) => {
  const item = currentInventoryItems.value[index];
  if (item.name.length < 2) {
    item.suggestions = [];
    return;
  }
  
  const selectedNames = new Set(
    currentInventoryItems.value
      .filter((_, i) => i !== index)
      .map(i => i.name.toLowerCase())
  );

  const searchRegex = new RegExp(item.name.split('').map(char => `${char}.*`).join(''), 'i');

  const getMatchScore = (str, searchTerm) => {
    const index = str.toLowerCase().indexOf(searchTerm.toLowerCase());
    return index === -1 ? Infinity : index;
  };

  item.suggestions = allIngredients.value.reduce((acc, ingredient) => {
    const nameMatch = ingredient.name.match(searchRegex);
    const subcategoryMatch = ingredient.subcategory && ingredient.subcategory.name.match(searchRegex);

    if (nameMatch && !selectedNames.has(ingredient.name.toLowerCase())) {
      acc.push({ 
        ...ingredient, 
        isCategory: true,
        score: getMatchScore(ingredient.name, item.name)
      });
    } else if (subcategoryMatch) {
      acc.push({ 
        ...ingredient, 
        isSubcategory: true,
        score: getMatchScore(ingredient.subcategory.name, item.name) + 1000
      });
    }
    return acc;
  }, [])
  .sort((a, b) => a.score - b.score)
  .slice(0, 10);

  const updatedItems = [...currentInventoryItems.value];
  emit(`update:${inventoryType.value}Items`, updatedItems);
};

  const clearList = () => {
 if (confirm('Are you sure you want to clear the list?')) {

  clearL(inventoryType.value);
  if (inventoryType.value === 'pantry') {
    pantryItems.value = [];
  } else if (inventoryType.value === 'freezer') {
    freezerItems.value = [];
  } else {
    perishableItems.value = [];
  }
}
};
const clearL = async (listType) => {
  const list = getListByType(listType);
  list.value = [{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null }];
  updateCache();
  try {
    await axios.post('/api/ingredients/clear_user_list/', {
      list_type: listType
    });
  } catch (error) {
    console.error('Error clearing ingredients:', error);
  }
};
const removeItem = (index) => {
    removeI(inventoryType.value, index);
};
onMounted(async () => {
  try {
    const response = await axios.get('/api/ingredients/all');
    allIngredients.value = response.data.map(ingredient => ({
      ...ingredient,
      measurements: ingredient.measurements.map(m => ({ id: m.id, name: m.name }))
    }));
    } catch (error) {
      console.error('Error loading ingredients:', error);
    }
    // Update the existing function
    try {
      const userListResponse = await axios.get('/api/ingredients/get_user_list/');
      
      // Helper function to process ingredient list
      const processIngredientList = (listData) => {
        if (listData && listData.ingredients && listData.ingredients.length > 0) {
          return listData.ingredients.map(ingredient => {
            
            const fullIngredientData = allIngredients.value.find(i => i.id === ingredient.id);
            if (!fullIngredientData) {
              console.error(`Ingredient with id ${ingredient.id} not found in allIngredients`);
              return null;
            }
            return {
              name: fullIngredientData.name,
              selected: fullIngredientData,
              measurement: fullIngredientData.measurements.find(m => m.id === ingredient.measurement_id),
              measurements: fullIngredientData.measurements,
              amount: ingredient.amount,
              suggestions: [],
              subcategory: fullIngredientData.subcategory.name
            };
          }).filter(Boolean); // Remove any null values
        }
        return [{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }];
      };

      // Process shopping list
      if (userListResponse.data.shopping) {
        selectedIngredients.value = processIngredientList(userListResponse.data.shopping, allIngredients.value);
      } else {
        selectedIngredients.value = [{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }];
      }

      // Process pantry list
      if (userListResponse.data.pantry) {
        pantryItems.value = processIngredientList(userListResponse.data.pantry, allIngredients.value);
      } else {
        pantryItems.value = [{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }];
      }


      // Process perishables list
      if (userListResponse.data.perishables) {
        perishableItems.value = processIngredientList(userListResponse.data.perishables, allIngredients.value);
      } else {
        perishableItems.value = [{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }];
      }
      // Process feezer list
      if (userListResponse.data.freezer) {
        freezerItems.value = processIngredientList(userListResponse.data.freezer, allIngredients.value);
      } else {
        freezerItems.value = [{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }];
      }
    } catch (error) {
      console.error('Error loading data:', error);
      selectedIngredients.value = [{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }];
      pantryItems.value = [{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }];
      perishableItems.value = [{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }];
      freezerItems.value = [{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }];
    }
  window.scrollTo(0, 0);
  isLoading.value = false;
});

const updateCache = () => {
  axios.get('/api/pantry/update_cache/', {
    headers: {
      'Authorization': `Bearer ${store.state.token}`
    }
  }).catch(error => {
    console.error('Error updating cache:', error);
  });
};

  </script>
  
  <style scoped>
  /* Add any component-specific styles here */
  </style>