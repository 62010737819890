<template>
  <footer class="bg-[#3e5461] text-gray-300 font-main py-8">
    <div class="container mx-auto px-4">
      <!-- Main footer content -->
      <div class="flex flex-wrap justify-between items-start mb-8">
        <!-- About section -->
        <div class="w-full md:w-1/4 mb-6 md:mb-0">
          <h3 class="text-lg font-semibold text-white mb-2">MealMatcher</h3>
          <p class="text-sm">
            Personalised meal plans tailored to your health goals, budget, and dietary preferences.
          </p>
        </div>

        <!-- Quick Links section -->
        <div class="w-full md:w-1/4 mb-6 md:mb-0">
          <h3 class="text-lg font-semibold text-white mb-2">Quick Links</h3>
          <ul class="text-sm space-y-2">
            <li><a href="/how-to-use" class="hover:text-white transition duration-300">How It Works</a></li>
            <li><a href="/#plans" class="hover:text-white transition duration-300">Our Plans</a></li>
            <li><a href="/about" class="hover:text-white transition duration-300">About Us</a></li>
            <li><a href="/#faq" class="hover:text-white transition duration-300">FAQ</a></li>
          </ul>
        </div>

        <!-- Newsletter section -->
        <div class="w-full md:w-2/5">
          <h3 class="text-lg font-semibold text-white mb-2">Stay Updated</h3>
          <form class="flex">
            <input
              type="email"
              required
              placeholder="Your email"
              class="w-2/3 px-3 py-2 text-sm rounded-l-md bg-gray-700 border-gray-600 text-white focus:outline-none focus:ring-1 focus:ring-blue-500"
            />
            <button
              type="submit"
              class="w-1/3 bg-blue-600 text-white px-4 py-2 text-sm rounded-r-md hover:bg-blue-700 transition duration-300"
            >
              Subscribe
            </button>
          </form>
        </div>
      </div>

      <!-- Divider -->
      <div class="border-t border-gray-700 my-4"></div>

      <!-- Bottom footer -->
      <div class="flex flex-col md:flex-row justify-between items-center text-sm">
        <p class="mb-4 md:mb-0">
          © 2023 MealMatcher. All rights reserved.
        </p>
        <div class="flex space-x-4 mb-4 md:mb-0">
          <a href="#" class="hover:text-white transition duration-300">
            <span class="sr-only">Facebook</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <!-- Facebook icon path -->
            </svg>
          </a>
          <a href="https://www.instagram.com/mealmatcher_uk/" class="hover:text-white transition duration-300">
            <span class="sr-only">Instagram</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <!-- Instagram icon path -->
            </svg>
          </a>
          <a href="#" class="hover:text-white transition duration-300">
            <span class="sr-only">Twitter</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <!-- Twitter icon path -->
            </svg>
          </a>
        </div>
        <div class="flex space-x-4">
          <a href="/privacy-policy" class="hover:text-white transition duration-300">Privacy Policy</a>
          <a href="/terms-of-service" class="hover:text-white transition duration-300">Terms of Service</a>
        </div>
      </div>
    </div>
  </footer>
</template>