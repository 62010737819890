<template>
    <div class="bg-white rounded-lg shadow-2xl w-full md:h-screen md:overflow-y-auto md:overscroll-none no-scrollbar flex flex-col">
      <div class="p-8 flex-grow md:overflow-y-auto ">
        <div class="w-full flex justify-between items-center mb-6">
          <h2 class="text-2xl font-main font-semibold  text-stone-800">
            {{ recipeForm.name.length > 0 ? recipeForm.name : 'New Recipe' }}
          </h2>
          <button @click="closeRecipeForm" class="text-gray-400 hover:text-gray-600 transition duration-300">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        
        <div v-if="errors.non_field_errors" class="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
          {{ errors.non_field_errors.join(', ') }}
        </div>
  
        <form @submit.prevent="saveRecipe" class="space-y-8">
          <!-- Basic Information -->
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div class="space-y-2">
              <label for="name" class="block text-sm font-medium text-gray-700">Recipe Name</label>
              <input
                id="name"
                v-model.trim="recipeForm.name"
                type="text"
                required
                :class="['w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500', 
                         {'border-red-500': errors.name, 'border-gray-300': !errors.name}]"
                @input="clearError('name')"
              >
              <p v-if="errors.name" class="mt-1 text-sm text-red-600">{{ errors.name.join(', ') }}</p>
            </div>
            <div class="space-y-2">
              <label for="cuisine" class="block text-sm font-medium text-gray-700">Cuisine</label>
              <select
                id="cuisine"
                v-model="recipeForm.cuisine"
                required
                :class="['w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500', 
                         {'border-red-500': errors.cuisine, 'border-gray-300': !errors.cuisine}]"
                @change="clearError('cuisine')"
              >
                <option value="">Select Cuisine</option>
                <option v-for="cuisine in sortedCuisines" :key="cuisine.id" :value="cuisine">{{ cuisine.name }}</option>
              </select>
              <p v-if="errors.cuisine" class="mt-1 text-sm text-red-600">{{ errors.cuisine }}</p>
            </div>
          </div>
  
          <div class="space-y-2">
            <label for="description" class="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              id="description"
              v-model="recipeForm.description"
              rows="3"
              :class="['w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500', 
                       {'border-red-500': errors.description, 'border-gray-300': !errors.description}]"
              @input="clearError('description')"
            ></textarea>
            <p v-if="errors.description" class="mt-1 text-sm text-red-600">{{ errors.description.join(', ') }}</p>
          </div>
  
          <!-- Time and Complexity -->
          <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div class="space-y-2">
              <label for="prep_time" class="block text-sm font-medium text-gray-700">Prep Time (minutes)</label>
              <input
                id="prep_time"
                v-model.number="recipeForm.prep_time"
                type="number"
                min="0"
                required
                :class="['w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500', 
                         {'border-red-500': errors.prep_time, 'border-gray-300': !errors.prep_time}]"
                @input="clearError('prep_time')"
              >
              <p v-if="errors.prep_time" class="mt-1 text-sm text-red-600">{{ errors.prep_time.join(', ') }}</p>
            </div>
            <div class="space-y-2">
              <label for="cook_time" class="block text-sm font-medium text-gray-700">Cook Time (minutes)</label>
              <input
                id="cook_time"
                v-model.number="recipeForm.cook_time"
                type="number"
                min="0"
                required
                :class="['w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500', 
                         {'border-red-500': errors.cook_time, 'border-gray-300': !errors.cook_time}]"
                @input="clearError('cook_time')"
              >
              <p v-if="errors.cook_time" class="mt-1 text-sm text-red-600">{{ errors.cook_time.join(', ') }}</p>
            </div>
            <div class="space-y-2">
              <label for="complexity" class="block text-sm font-medium text-gray-700">Complexity</label>
              <select
                id="complexity"
                v-model="recipeForm.complexity"
                required
                :class="['w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500', 
                         {'border-red-500': errors.complexity, 'border-gray-300': !errors.complexity}]"
                @change="clearError('complexity')"
              >
                <option value="simple">Simple</option>
                <option value="intermediate">Moderate</option>
                <option value="advanced">Complex</option>
              </select>
              <p v-if="errors.complexity" class="mt-1 text-sm text-red-600">{{ errors.complexity.join(', ') }}</p>
            </div>
          </div>
  
          <!-- Meal Types -->
          <div class="space-y-2 w-full">
            <label class="block text-sm font-medium text-gray-700">Meal Types</label>
            <div class="flex flex-wrap justify-around gap-2">
              <div 
                v-for="mealType in mealTypes"
                :key="mealType.id"
                @click="toggleMealType(mealType)"
                class="cursor-pointer p-2 rounded-md text-sm transition-colors duration-200 ease-in-out"
                :class="isMealTypeSelected(mealType) ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'"
              >
                {{ mealType.name }}
              </div>
            </div>
            <p v-if="errors.meal_types" class="mt-1 text-sm text-red-600">{{ errors.meal_types.join(', ') }}</p>
          </div>
  
          <!-- Ingredients -->
          <div class="space-y-4">
            <h3 class="text-lg font-medium text-gray-700">Ingredients</h3>
            <!-- New information box -->
            <div class="bg-blue-50 border-l-4 border-blue-400 p-4 mb-4">
              <div class="flex">
                <div class="flex-shrink-0">
                  <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                  </svg>
                </div>
                <div class="ml-3">
                  <p class="text-sm text-blue-700">
                    Please enter ingredients for a single serving. This meal planner is designed to create varied meals using the same ingredients in different ways, not for batch cooking.
                  </p>
                </div>
              </div>
            </div>
            <table class="w-full border-collapse">
              <thead v-if="recipeForm.ingredients.length > 0">
                <tr class="text-gray-700 text-sm">
                  <th class="p-2"></th>
                  <th class="p-2 text-left">Ingredient</th>
                  <th class="p-2 text-left">Quantity</th>
                  <th class="p-2 text-left">Unit</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(ingredient, index) in recipeForm.ingredients" :key="index" class="border-b">
                  <td class="p-2">
                    <button @click.prevent="removeIngredient(index)" class="text-red-600 hover:text-red-800">
                      <span class="sr-only">Remove</span>
                      <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                    </button>
                  </td>
                  <td class="p-2">
                    <div v-if="!ingredient.ingredient.id" class="relative">
                      <input 
                        v-model="ingredient.searchTerm"
                        @input="filterIngredients(index)"
                        type="text"
                        placeholder="Search ingredient"
                        class="w-full px-2 py-1 border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                      >
                      <ul v-if="ingredient.suggestions.length" class="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-sm max-h-40 overflow-y-auto">
                        <li 
                          v-for="suggestion in ingredient.suggestions" 
                          :key="suggestion.id" 
                          @click="selectIngredient(index, suggestion)"
                          class="px-3 py-1 hover:bg-gray-100 cursor-pointer"
                        >
                          {{ suggestion.name }}
                        </li>
                      </ul>
                    </div>
                    <div v-else>
                      {{ ingredient.ingredient.name }}
                    </div>
                  </td>
                  <td class="p-2">
                    <input 
                      v-model.number="ingredient.quantity"
                      type="number"
                      min="0"
                      step="0.1"
                      required 
                      placeholder="Quantity"
                      :class="['w-full px-2 py-1 border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500',
                              {'border-red-500': errors.ingredients && errors.ingredients[index]?.quantity, 'border-gray-300': !errors.ingredients || !errors.ingredients[index]?.quantity}]"
                      @input="clearIngredientError(index, 'quantity')"
                    >
                    <p v-if="errors.ingredients && errors.ingredients[index]?.quantity" class="mt-1 text-sm text-red-600">
                      {{ errors.ingredients[index].quantity.join(', ') }}
                    </p>
                  </td>
                  <td class="p-2">
                    <select 
                      v-model="ingredient.unit.id"
                      required 
                      :class="['w-full px-2 py-1 border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500',
                              {'border-red-500': errors.ingredients && errors.ingredients[index]?.unit, 'border-gray-300': !errors.ingredients || !errors.ingredients[index]?.unit}]"
                      @change="clearIngredientError(index, 'unit')"
                    >
                      <option value="">Select Unit</option>
                      <option v-for="measurement in ingredient.availableMeasurements" :key="measurement.id" :value="measurement.id">
                        {{ measurement.name }}
                      </option>
                    </select>
                    <p v-if="errors.ingredients && errors.ingredients[index]?.unit" class="mt-1 text-sm text-red-600">
                      {{ errors.ingredients[index].unit.join(', ') }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p v-if="errors.ingredients" class="mt-1 text-sm text-red-600">{{ errors.ingredients.join(', ') }}</p>
          </div>
          
          <!-- Instructions -->
          <div class="space-y-4">
            <h3 class="text-lg font-medium text-gray-700">Instructions</h3>
            <div v-for="(instruction, index) in recipeForm.instructions" :key="index" class="flex items-start space-x-2">
              <button @click.prevent="removeInstruction(index)" class="mt-2 text-red-600 hover:text-red-800">
                <span class="sr-only">Remove</span>
                <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
              </button>
              <textarea 
                v-model="instruction.text"
                required 
                :placeholder="`Step ${index + 1}`"
                rows="2"
                :class="['flex-grow px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 resize-y',
                         {'border-red-500': errors.instructions && errors.instructions[index], 'border-gray-300': !errors.instructions || !errors.instructions[index]}]"
                @input="clearInstructionError(index)"
              ></textarea>
            </div>
            <p v-if="errors.instructions" class="mt-1 text-sm text-red-600">{{ errors.instructions.join(', ') }}</p>
          </div>
        </form>
      </div>
      <!-- Fixed bottom buttons -->
      <div class="sticky bottom-0 left-0 right-0 border-t bg-slate-50 border-stone-200 flex justify-around items-center py-4">
      <button @click="closeRecipeForm" type="button" class="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-md font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 transition duration-300 ease-in-out">
        Cancel
      </button>
      <div class="flex space-x-4">

        <button @click="saveRecipe" type="submit" class="px-4 py-2 border border-transparent text-md font-medium rounded-md text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 transition duration-300 ease-in-out">
          {{ editingRecipe ? 'Update Recipe' : 'Add Recipe' }}
        </button>
      </div>
    </div>
    </div>
  </template>
<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';

const store = useStore();

const props = defineProps(['recipe', 'cuisines', 'availableIngredients']);
const emit = defineEmits(['close']);

const sortedCuisines = computed(() => {
return [...props.cuisines].sort((a, b) => 
  a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
);
});
const availableIngredients = ref(props.availableIngredients);
const editingRecipe = ref(null);
const availableMeasurements = ref([]);
const errors = ref({});

const mealTypes = ref([{ name: 'Breakfast', id: '1' }, { name: 'Lunch', id: '2' }, { name: 'Dinner', id: '3' }, { name: 'Snack', id: '4' }]);
const recipeForm = ref({
  name: '',
  description: '',
  ingredients: [{ ingredient: { id: null, name: '' }, quantity: 0, unit: { id: null, name: '' }, multipliers: [1], group: '', searchTerm: '', suggestions: [] }],
  instructions: [{ text: '' }],
  meal_types: [],
  cuisine: { id: null, name: '' },
  complexity: 'simple',
  prep_time: 0,
  cook_time: 0
});

onMounted(() => {
    window.scrollTo(0, 0);
  if (props.recipe) {
    editRecipe(props.recipe);
  }
});

const editRecipe = (recipe) => {
  editingRecipe.value = recipe;
  recipeForm.value = {
    ...recipe,
    cuisine: { id: recipe.cuisine.id, name: recipe.cuisine.name },
    meal_types: recipe.meal_types.map(mt => ({ id: String(mt.id), name: mt.name })),
    ingredients: [
      ...recipe.ingredients.map(ing => {
        const availableIng = availableIngredients.value.find(ai => ai.id === ing.ingredient.id);
        const measurement = availableIng ? availableIng.measurements.find(m => m.id === ing.unit.id) : null;
        return {
          ...ing,
          unitName: measurement ? measurement.name : '',
          availableMeasurements: availableIng ? availableIng.measurements : [],
          group: ing.group || '',
          multipliers: ing.multipliers || [1] 
        };
      }),
      // Add an empty ingredient slot
      {
        ingredient: { id: null, name: '' },
        quantity: 0,
        unit: { id: null, name: '' },
        multipliers: [1],
        group: '',
        searchTerm: '',
        suggestions: [],
        availableMeasurements: []
      }
    ],
    instructions: [
      ...recipe.instructions,
      { text: '' } // Existing empty instruction slot
    ]
  };
};

const toggleMealType = (mealType) => {
  const index = recipeForm.value.meal_types.findIndex(mt => mt.id === mealType.id);
  if (index === -1) {
    recipeForm.value.meal_types.push(mealType);
  } else {
    recipeForm.value.meal_types.splice(index, 1);
  }
  clearError('meal_types');
};

const isMealTypeSelected = (mealType) => {
  return recipeForm.value.meal_types.some(mt => mt.id === mealType.id);
};

const updateIngredientMeasurements = (index) => {
  const ingredient = recipeForm.value.ingredients[index];
  
  const availableIng = availableIngredients.value.find(ai => ai.id === ingredient.ingredient.id);
  if (availableIng) {
    ingredient.availableMeasurements = availableIng.measurements;
    if (!availableIng.measurements.some(m => m.id === ingredient.unit.id)) {
      ingredient.unit.id = availableIng.measurements[0].id;
      ingredient.unit.name = availableIng.measurements[0].name;
    }
  } else {
    ingredient.availableMeasurements = [];
  }
  clearIngredientError(index, 'ingredient');
};

const addIngredient = () => {
  recipeForm.value.ingredients.push({
    ingredient: { id: null, name: '' },
    quantity: 0,
    unit: { id: null, name: '' },
    multipliers: [1],
    group: '',
    searchTerm: '',
    suggestions: []
  });
};
const filterIngredients = (index) => {
  const item = recipeForm.value.ingredients[index];
  if (item.searchTerm.length < 2) {
    item.suggestions = [];
    return;
  }
  
  const selectedNames = new Set(
    recipeForm.value.ingredients
      .filter((_, i) => i !== index)
      .map(i => i.ingredient.name.toLowerCase())
  );

  // Create a regex that matches the input characters in order
  const searchRegex = new RegExp(item.searchTerm.split('').map(char => `${char}.*`).join(''), 'i');

  // Function to calculate match score (lower is better)
  const getMatchScore = (str, searchTerm) => {
    const index = str.toLowerCase().indexOf(searchTerm.toLowerCase());
    return index === -1 ? Infinity : index;
  };

  // Search in main ingredients and subcategories
  item.suggestions = availableIngredients.value.reduce((acc, ingredient) => {
    const nameMatch = ingredient.name.match(searchRegex);
    const subcategoryMatch = ingredient.subcategory && ingredient.subcategory.name.match(searchRegex);

    if (nameMatch && !selectedNames.has(ingredient.name.toLowerCase())) {
      acc.push({ 
        ...ingredient, 
        isCategory: true,
        score: getMatchScore(ingredient.name, item.searchTerm)
      });
    } else if (subcategoryMatch) {
      acc.push({ 
        ...ingredient, 
        isSubcategory: true,
        score: getMatchScore(ingredient.subcategory.name, item.searchTerm) + 1000 // Prioritize main ingredient matches
      });
    }
    return acc;
  }, [])
  .sort((a, b) => a.score - b.score) // Sort by score
  .slice(0, 8);
};

const selectIngredient = (index, suggestion) => {
  const ingredient = recipeForm.value.ingredients[index];
  ingredient.ingredient = { id: suggestion.id, name: suggestion.name };
  ingredient.searchTerm = '';
  ingredient.suggestions = [];
  if (index === recipeForm.value.ingredients.length - 1) {
    addIngredient();
  }
  updateIngredientMeasurements(index);
};
const addInstruction = () => {
  recipeForm.value.instructions.push({ text: '' });
};

const removeIngredient = (index) => {
  if (recipeForm.value.ingredients.length > 1) {
    recipeForm.value.ingredients.splice(index, 1);
  }
};
const removeInstruction = (index) => {
  if (recipeForm.value.instructions.length > 1) {
    recipeForm.value.instructions.splice(index, 1);
  }
};

const closeRecipeForm = () => {
  emit('close');
};
watch(() => recipeForm.value.instructions[recipeForm.value.instructions.length - 1].text, (newValue) => {
  if (newValue.trim() !== '') {
    recipeForm.value.instructions.push({ text: '' });
  }
});
const resetRecipeForm = () => {
  recipeForm.value = {
    name: '',
    description: '',
    ingredients: [],
    instructions: [],
    meal_types: [],
    cuisine: { id: null, name: '' },
    complexity: 'simple',
    prep_time: 0,
    cook_time: 0
  };
  errors.value = {};
};

const clearError = (field) => {
  if (errors.value[field]) {
    delete errors.value[field];
  }
};

const clearIngredientError = (index, field) => {
  if (errors.value.ingredients && errors.value.ingredients[index] && errors.value.ingredients[index][field]) {
    delete errors.value.ingredients[index][field];
  }
};

const clearInstructionError = (index) => {
  if (errors.value.instructions && errors.value.instructions[index]) {
    delete errors.value.instructions[index];
  }
};

const saveRecipe = async () => {
  try {
    let formData = {
      name: recipeForm.value.name,
      description: recipeForm.value.description,
      complexity: recipeForm.value.complexity,
      prep_time: recipeForm.value.prep_time,
      cook_time: recipeForm.value.cook_time,
      ingredients: recipeForm.value.ingredients
        .filter(ingredient => ingredient.ingredient.id !== null)
        .map(ingredient => ({
          ingredient: ingredient.ingredient,
          quantity: ingredient.quantity,
          unit: ingredient.unit,
          multipliers: ingredient.multipliers,
          group: ingredient.group
        })),
        instructions: recipeForm.value.instructions
        .filter(instruction => instruction.text !== '')
        .map((instruction, index) => ({
          step_number: index + 1,
          text: instruction.text
        })),
      meal_types: recipeForm.value.meal_types.map(mt => ({
        id: mt.id,
        name: mt.name
      })),
      cuisine: recipeForm.value.cuisine
    };

    if (editingRecipe.value) {
      formData.id = editingRecipe.value.id;
    }

    const config = {
      headers: {
        'Authorization': `Bearer ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    };

    let response;
    if (editingRecipe.value) {
      response = await axios.put(`/api/recipe/${editingRecipe.value.id}/`, formData, config);
    } else {
      response = await axios.post('/api/recipe/', formData, config);
    }

    if (response.status === 200 || response.status === 201) {
      emit('close');
      editingRecipe.value = null;
      resetRecipeForm();
      // You might want to emit an event to refresh the recipe list in the parent component
      emit('recipe-saved', response.data);
      
    }
  } catch (error) {
    console.error('Error saving recipe:', error);
    if (error.response && error.response.data) {
      errors.value = error.response.data;
      console.error('Server error details:', error.response.data);
    } else {
      errors.value = { non_field_errors: ['An unexpected error occurred. Please try again.'] };
    }
  }
};
</script>