<template>
    <div class="min-h-screen flex flex-col bg-[#e8f3fa]">
        <!-- Authentication Check -->
  <div v-if="!authenticated" class="w-full flex justify-center items-center h-screen">
    <div class="bg-gray-100 p-10 rounded-lg shadow-lg">
            <h1 class="text-3xl mb-4">Welcome to the Meal Planner</h1>
            <p class="mb-4">To get started please log in or sign up</p>
            <div class="flex justify-between">
              <router-link to="/login" class="bg-s-t text-white rounded px-4 py-2 mr-2">Sign In</router-link>
              <router-link to="/signup" class="bg-interactive text-white rounded px-4 py-2 mr-2">Sign Up</router-link>
            </div>
          </div>
  </div>

  <!-- Main Content -->
  <div v-else>
      <!-- Header -->
      <header class="bg-white shadow">
        <div class="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:px-8 flex items-center justify-between">
          <div>
            <h1 class="text-2xl font-bold text-gray-900">Shopping Lists</h1>
            <p class="mt-1 text-gray-600">Plan your shopping and compare prices across supermarkets.</p>
          </div>
          <!-- Add List Button -->
          <button
            @click="createNewList"
            :disabled="isLoading || !isPremiumUser"
            class="inline-flex items-center px-4 py-2 bg-green-600 text-white text-sm font-medium rounded-md hover:bg-green-700 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
            >
            <svg
              class="-ml-1 mr-2 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
            </svg>
            New List
          </button>
        </div>
        <!-- List Tabs -->
      <div class="mt-4 border-b border-gray-200 w-full">
        <nav class="-mb-px flex space-x-8 px-4 sm:px-6 lg:px-8 w-full overflow-x-auto" aria-label="Tabs">
          <!-- Loading Skeleton -->
          <div v-if="isLoading" class="flex space-x-8 w-full overflow-x-auto">
            <div v-for="n in 5" :key="n" class="group inline-flex items-center py-1 px-1 border-b-2 border-transparent">
              <div class="h-6 w-24 bg-gray-200 rounded animate-pulse"></div>
            </div>
          </div>
          <!-- Actual Lists -->
          <div v-else v-for="list in shoppingLists" :key="list.id"
            @click="selectList(list.id)"
            class="group inline-flex items-center py-1 px-1 border-b-2 cursor-pointer"
            :class="selectedListId === list.id ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'"
          >
            <span class="capitalize">{{ list.name }}</span>
            <!-- Rename and Delete Icons -->
            <div class="ml-2 flex space-x-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
              <button @click.stop="promptRenameList(list)" class="text-gray-400 hover:text-gray-600">
                <span class="sr-only">Rename</span>
                <svg class="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15.232 5.232l3.536 3.536M9 13h6M13.5 4.5L9 9m0 0l-3.536 3.536a2 2 0 102.828 2.828l3.536-3.536m0 0L15 13"
                  />
                </svg>
              </button>
              <button @click.stop="deleteList(list.id)" class="text-gray-400 hover:text-gray-600">
                <span class="sr-only">Delete</span>
                <svg class="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 7l-.867 12.142a2 2 0 01-1.993 1.858H7.86a2 2 0 01-1.993-1.858L5 7m5 4v6m4-6v6M9 7h6m-1 0V4a1 1 0 00-1-1h-2a1 1 0 00-1 1v3h4z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </nav>
      </div>
    </header>
  
      <!-- Main Content -->
      <main class="flex-1 overflow-y-auto">
        <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <!-- Loading Message -->
          <div v-if="isLoading" class="text-center py-10">
            <p class="text-xl font-semibold text-gray-700">Loading shopping lists...</p>
            <p class="mt-2 text-gray-500">Please wait while we fetch your data.</p>
          </div>
          <!-- Responsive Layout -->
          <div v-else class="flex flex-col lg:flex-row gap-8">
            <!-- Shopping List Section -->
            <div class="flex-1 lg:max-w-md bg-white  rounded-md p-2 drop-shadow-md">
              <!-- Ingredient Input -->
              <div class="mb-6">
                <h2 class="text-xl font-semibold text-gray-800 mb-2">Add Ingredient to "{{ currentListName }}"</h2>
                <div class="flex flex-col space-y-4">
                  <!-- Ingredient Selector -->
                  <div>
                    <label for="ingredientSearch" class="block text-sm font-medium text-gray-700 mb-1">Search Ingredient</label>
                    <input
                      id="ingredientSearch"
                      type="text"
                      v-model="ingredientSearchQuery"
                      @input="filterIngredients"
                      placeholder="Type to search..."
                      class="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    <!-- Ingredient Suggestions -->
                    <ul v-if="ingredientSuggestions.length && ingredientSearchQuery.trim()" class="mt-2 bg-white border border-gray-200 rounded-md shadow-md max-h-60 overflow-auto">
                      <li
                        v-for="suggestion in ingredientSuggestions"
                        :key="suggestion.id"
                        @click="selectIngredient(suggestion)"
                        class="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                      >
                        <img v-if="suggestion.thumbnail" :src="suggestion.thumbnail" alt="Ingredient thumbnail" class="h-8 w-8 rounded-full mr-3" />
                        <div v-else class="h-8 w-8 bg-gray-200 rounded-full mr-3"></div>
                        <span>{{ suggestion.name }}</span>
                      </li>
                    </ul>
                  </div>
                  <!-- Selected Ingredient -->
                  <div v-if="selectedIngredient">
                    <div class="flex items-center">
                      <img v-if="selectedIngredient.thumbnail" :src="selectedIngredient.thumbnail" alt="Ingredient thumbnail" class="h-10 w-10 rounded-full mr-3" />
                      <div v-else class="h-10 w-10 bg-gray-200 rounded-full mr-3"></div>
                      <span class="text-lg font-medium">{{ selectedIngredient.name }}</span>
                    </div>
                  </div>
                  <!-- Amount and Measurement -->
                  <div v-if="selectedIngredient" class="flex space-x-4">
                    <!-- Amount Input -->
                    <div class="flex-1">
                      <label for="ingredientAmount" class="block text-sm font-medium text-gray-700 mb-1">Amount</label>
                      <input
                        id="ingredientAmount"
                        type="number"
                        min="0"
                        step="any"
                        v-model.number="ingredientAmount"
                        class="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      />
                    </div>
                    <!-- Measurement Selector -->
                    <div class="flex-1">
                      <label for="ingredientMeasurement" class="block text-sm font-medium text-gray-700 mb-1">Measurement</label>
                      <select
                        id="ingredientMeasurement"
                        v-model="ingredientMeasurement"
                        class="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      >
                        <option v-for="measurement in selectedIngredient.measurements" :key="measurement.id" :value="measurement">
                          {{ measurement.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!-- Add Button -->
                  <div v-if="selectedIngredient">
                    <button
                      @click="addItem"
                      class="w-full px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none"
                    >
                      Add Ingredient
                    </button>
                  </div>
                  <!-- Error Message -->
                  <p v-if="itemError" class="mt-1 text-sm text-red-600">{{ itemError }}</p>
                </div>
              </div>
  
              <!-- Shopping List Items -->
              <div class="mb-8">
                <h2 class="text-xl font-semibold text-gray-800 mb-4">Ingredients in "{{ currentListName }}"</h2>
                <ul class="divide-y divide-gray-200 bg-white shadow rounded-md">
                  <li
                    v-for="(item, index) in currentListItems"
                    :key="item.selected.id"
                    class="flex items-center py-4 px-4"
                  >
                    <div class="flex-1 flex items-center">
                      <input
                        type="checkbox"
                        v-model="item.checked"
                        class="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                      />
                      <img v-if="item.selected.thumbnail" :src="item.selected.thumbnail" alt="Ingredient thumbnail" class="h-8 w-8 rounded-full ml-4 mr-3" />
                      <div v-else class="h-8 w-8 bg-gray-200 rounded-full ml-4 mr-3"></div>
                      <div>
                        <p :class="{'line-through text-gray-500': item.checked}" class="text-sm font-medium text-gray-900">
                          {{ item.selected.name }}
                        </p>
                        <p class="text-sm text-gray-500">{{ item.amount }} {{ item.measurement.name }}</p>
                      </div>
                    </div>
                    <button
                    @click="removeItem(index)"
                    :disabled="isLoading"
                    class="text-red-600 hover:text-red-800 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    Remove
                  </button>
                  </li>
                  <li v-if="currentListItems.length === 0" class="py-4 px-4 text-gray-500">
                    No ingredients in this list.
                  </li>
                </ul>
              </div>
  
              <!-- Update Price Comparisons Button -->
              <div class="mb-8">
                <button
                @click="updateComparisons"
                :disabled="isLoading"
                class="w-full px-6 py-3 bg-green-600 text-white text-lg font-medium rounded-md hover:bg-green-700 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Update Price Comparisons
              </button>
              </div>
            </div>
  
            <!-- Price Comparison Section -->
  <div v-if="comparisonsVisible" class="flex-1 bg-white p-2 rounded-md drop-shadow-md">
    <h2 class="text-xl font-semibold text-gray-800 mb-4">Price Comparisons</h2>
    <!-- Loading state for comparisons -->
    <div v-if="isLoading" class="animate-pulse">
      <div v-for="i in 3" :key="i" class="mb-4 bg-gray-200 h-12 rounded"></div>
    </div>
    <!-- Comparison results -->
    <div v-else>
     <!-- Tabs for Supermarkets -->
    <nav class="flex space-x-4 mb-4 overflow-x-auto" aria-label="Tabs">
        <button
    v-for="(supermarket, index) in sortedSupermarkets"
    :key="supermarket.name"
    @click="activeSupermarketIndex = index"
    :class="activeSupermarketIndex === index ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-600 hover:bg-gray-200'"
    class="flex-shrink-0 py-2 px-4 rounded-md text-center text-sm font-medium focus:outline-none flex items-center"
>
    <img :src="getSupermarketLogo(supermarket.name)" alt="" class="h-6 w-6 mr-2" />
    <span>{{ supermarket.name }}</span>
    <span class="ml-2 text-sm font-semibold">{{ formatPrice(supermarket.total_spend) }}</span>
    <svg v-if="supermarket.missing_ingredients.length > 0" class="ml-2 h-4 w-4 text-yellow-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-10V6a1 1 0 00-2 0v2a1 1 0 002 0zm0 4a1 1 0 10-2 0 1 1 0 002 0z" clip-rule="evenodd" />
    </svg>
</button>
    </nav>
      <!-- Mobile: List View -->
      <div class="lg:hidden bg-white shadow rounded-md">
        <div class="p-4">
          <!-- Supermarket Price List -->
          <h3 class="text-lg font-medium text-gray-800 mb-2 flex items-center">
            <img :src="getSupermarketLogo(activeSupermarket)" alt="" class="h-8 w-8 mr-2" />
            {{ activeSupermarket }}
          </h3>
          <ul class="divide-y divide-gray-200">
            <li
              v-for="(item, index) in shoppingList[activeSupermarket].shopping_list"
              :key="index"
              class="py-4 flex items-start"
            >
              <!-- Ingredient Info -->
              <div class="flex-shrink-0 w-16 h-16">
                <img 
                  v-if="currentListItems[index] && currentListItems[index].selected.thumbnail" 
                  :src="currentListItems[index].selected.thumbnail" 
                  :alt="currentListItems[index].selected.name"
                  class="w-full h-full object-cover rounded-md"
                />
                <div v-else class="w-full h-full bg-gray-200 rounded-md"></div>
              </div>
              <div class="flex-grow">
                <p class="text-sm font-medium text-gray-900">
                  {{ currentListItems[index] ? currentListItems[index].selected.name : 'Unknown Ingredient' }}
                </p>
                <p class="text-sm text-gray-500">
                  {{ currentListItems[index] ? `${currentListItems[index].amount} ${currentListItems[index].measurement.name}` : '' }}
                </p>
              </div>
              <!-- Supermarket Item Info -->
              <div class="flex-shrink-0 text-right">
                <p class="text-sm font-medium text-gray-900">{{ item.name }}</p>
                <p class="text-sm font-semibold text-gray-700">{{ formatPrice(item.price) }}</p>
                <p class="text-xs text-gray-500">Qty: {{ item.quantity }}</p>
                <p class="text-xs text-gray-500">Total: {{ formatPrice(item.total_price) }}</p>
              </div>
            </li>
          </ul>
          <!-- Total Price -->
          <div class="mt-4 border-t pt-4">
            <p class="text-sm font-semibold text-gray-900">
              Total: {{ formatPrice(shoppingList[activeSupermarket].total_spend) }}
            </p>
          </div>
        </div>
      </div>

      <!-- Desktop: Table View -->
      <div class="hidden lg:block overflow-x-auto bg-white shadow rounded-md">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-100">
            <tr>
              <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-600 uppercase tracking-wider">
                Item
              </th>
              <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-600 uppercase tracking-wider">
                Qty
              </th>
              <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-600 uppercase tracking-wider">
                Price
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(item, index) in shoppingList[activeSupermarket].shopping_list" :key="index">
              <td class="px-6 py-4 flex ">
                <!-- Supermarket Item Info -->
                <div class="flex-shrink-0 text-right">
                <p class="text-sm font-medium text-gray-900">{{ item.name }}</p>
                
              </div>
              </td>
              <td class="px-6 py-4">
              <div class="flex-shrink-0 text-right">
                <p class="text-xs text-gray-500">Qty: {{ item.quantity }}</p>
              </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-center">
                <div class="text-sm font-semibold text-gray-900">{{ formatPrice(item.price) }}</div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="bg-gray-100">
              <th colspan="2" scope="row" class="px-6 py-3 text-left text-sm font-medium text-gray-900">
                Total
              </th>
              <td class="px-6 py-3 whitespace-nowrap text-center text-sm font-semibold text-gray-900">
                {{ formatPrice(shoppingList[activeSupermarket].total_spend) }}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
  </div>

        </div>
      </main>
    </div>
  
    <!-- Rename List Modal -->
    <div v-if="isRenameModalOpen" class="fixed z-50 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen px-4">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
          <div class="px-6 py-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900">Rename List</h3>
            <div class="mt-2">
              <input
                type="text"
                v-model="renameListName"
                class="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
          </div>
          <div class="px-6 py-3 bg-gray-50 flex justify-end">
            <button @click="isRenameModalOpen = false" class="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 mr-2">Cancel</button>
            <button @click="renameList" class="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700">Save</button>
          </div>
        </div>
      </div>
    </div>
</div>
  </template>
  
  <script setup>
  import { ref, computed, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import axios from 'axios';
  
  const store = useStore();
  // State variables
  const authenticated = computed(() => store.state.authenticated);
const isPremiumUser = computed(() => store.getters.isPremiumUser)
const isSubscribed = computed(() => store.getters.isSubscribed) 
  const isLoading = ref(true);
  
  // Ingredient data
  const allIngredients = ref([]);
  
// Shopping lists data
const shoppingLists = ref([]); // Array of shopping lists
const selectedListId = ref(null);


// Ingredient search variables
const ingredientSearchQuery = ref('');
const ingredientSuggestions = ref([]);
const selectedIngredient = ref(null);
const ingredientAmount = ref(1);
const ingredientMeasurement = ref(null);
const comparisonsVisible = ref(false);
const itemError = ref('');
  // Rename list modal
const isRenameModalOpen = ref(false);
const renameListName = ref('');
let listToRename = null;
// Proposed fix
const activeSupermarket = computed(() => {
  if (!sortedSupermarkets.value.length) return '';
  return sortedSupermarkets.value[activeSupermarketIndex.value].name;
});

// Function to get supermarket logo
const getSupermarketLogo = (supermarket) => {
    const logos = {
  Combined: 'data:image/svg+xml;base64,' + btoa(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <defs>
      <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style="stop-color:#4CAF50;stop-opacity:1" />
        <stop offset="100%" style="stop-color:#2196F3;stop-opacity:1" />
      </linearGradient>
    </defs>
    <circle cx="50" cy="50" r="48" fill="url(#grad1)" stroke="#333" stroke-width="2"/>
    <g fill="none" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round">
      <path d="M30,70 L25,40 L75,40 L70,70 Z" />
      <path d="M35,50 L65,50" />
      <path d="M33,60 L67,60" />
      <circle cx="35" cy="75" r="5" />
      <circle cx="65" cy="75" r="5" />
    </g>
    <path d="M40,30 L60,30 L55,40 L45,40 Z" fill="white"/>
  </svg>`),
  Tesco: 'https://logo.clearbit.com/tesco.com',
  Aldi: 'https://logo.clearbit.com/aldi.co.uk',
  MandS: 'https://logo.clearbit.com/marksandspencer.com',
  Sainsburys: 'https://logo.clearbit.com/sainsburys.co.uk'
};
  return logos[supermarket] || '';
};
  const supermarketComparisons = ref({});
  const activeSupermarketIndex = ref(0);

// Fetch all ingredients and shopping lists on mount
onMounted(async () => {
  window.scrollTo(0, 0);
  if (authenticated.value) {
    try {
      const response = await axios.get('/api/ingredients/all');
      allIngredients.value = response.data.map(ingredient => ({
        ...ingredient,
        measurements: ingredient.measurements.map(m => ({ id: m.id, name: m.name })),
      }));
      // Sort ingredients by name
      allIngredients.value.sort((a, b) => a.name.localeCompare(b.name));

      // Initialize shopping lists
      await loadShoppingLists();
    } catch (error) {
      console.error('Error loading ingredients:', error);
    } 
  }
});
  // Load all shopping lists from the backend
  const loadShoppingLists = async () => {
  try {
    const response = await axios.get('/api/shopping_lists/');
    
    // Ensure response.data is an array
    const listsData = Array.isArray(response.data) ? response.data : [response.data];
    
    shoppingLists.value = listsData.map(list => ({
      id: list.id,
      name: list.name || `List ${list.id}`, // Provide a default name if it's null
      items: processIngredientList(list.ingredients), // Changed from list.items to list.ingredients
    }));
    
    if (shoppingLists.value.length > 0) {
      selectedListId.value = shoppingLists.value[0].id;
    } else {
      // If no lists exist, create a new one
      await createNewList();
    }
  } catch (error) {
    console.error('Error loading shopping lists:', error);
  } finally {
    isLoading.value = false;
  }
};
// Process ingredient list from backend data
const processIngredientList = (items) => {
  return items.map(ingredient => {
    const fullIngredientData = allIngredients.value.find(i => i.id === ingredient.id);
    if (!fullIngredientData) {
      console.error(`Ingredient with id ${ingredient.id} not found in allIngredients`);
      return null;
    }
    return {
      selected: fullIngredientData,
      amount: ingredient.amount,
      measurement: fullIngredientData.measurements.find(m => m.id === ingredient.measurement_id),
      checked: false,
    };
  }).filter(Boolean);
};
// Current shopping list
const currentList = computed(() => {
  return shoppingLists.value.find(list => list.id === selectedListId.value);
});
// Current list name
const currentListName = computed(() => {
  return currentList.value ? currentList.value.name : 'Unnamed List';
});
// Current list items
const currentListItems = computed(() => {
  return currentList.value ? currentList.value.items : [];
});
// Select a shopping list
const selectList = (id) => {
  selectedListId.value = id;
  onListChange();
};
// Triggered when the selected shopping list changes
const onListChange = () => {
  ingredientSearchQuery.value = '';
  ingredientSuggestions.value = [];
  selectedIngredient.value = null;
  ingredientAmount.value = 1;
  ingredientMeasurement.value = null;
  itemError.value = '';
  comparisonsVisible.value = false;
  supermarketComparisons.value = {};
};
// Create a new shopping list
const createNewList = async () => {
  const newListName = `New List ${shoppingLists.value.length + 1}`;
  try {
    const response = await axios.post('/api/shopping_lists/', {
      name: newListName,
      list_type: 'shopping'
    });
    const newList = {
      id: response.data.id,
      name: response.data.name,
      items: [],
    };
    shoppingLists.value.push(newList);
    selectedListId.value = newList.id;
    onListChange();
  } catch (error) {
    console.error('Error creating new list:', error);
  }
};
// Delete a shopping list
const deleteList = async (id) => {
  const index = shoppingLists.value.findIndex(list => list.id === id);
  if (index !== -1) {
    if (confirm(`Are you sure you want to delete "${shoppingLists.value[index].name}"?`)) {
      try {
        shoppingLists.value.splice(index, 1);
        if (shoppingLists.value.length > 0) {
          selectedListId.value = shoppingLists.value[0].id;
        } else {
          await createNewList();
        }
        onListChange();
        await axios.delete(`/api/shopping_lists/${id}/`);
        
      } catch (error) {
        console.error('Error deleting list:', error);
      }
    }
  }
};
// Prompt rename list modal
const promptRenameList = (list) => {
  listToRename = list;
  renameListName.value = list.name;
  isRenameModalOpen.value = true;
};
// Rename a shopping list
const renameList = async () => {
  if (!renameListName.value.trim()) {
    alert('List name cannot be empty.');
    return;
  }
  try {
    // Frontend update
    listToRename.name = renameListName.value.trim();
    isRenameModalOpen.value = false;
    // Backend call
    await axios.post(`/api/shopping_lists/${listToRename.id}/rename/`, {
      name: listToRename.name,
    });
  } catch (error) {
    console.error('Error renaming list:', error);
  }
};
 // Filter ingredients based on search query
const filterIngredients = () => {
  if (ingredientSearchQuery.value.length < 2) {
    ingredientSuggestions.value = [];
    return;
  }

  const query = ingredientSearchQuery.value.toLowerCase();
  const selectedNames = new Set(currentListItems.value.map(item => item.selected.name.toLowerCase()));

  const searchRegex = new RegExp(query.split('').map(char => `${char}.*`).join(''), 'i');

  const getMatchScore = (str, searchTerm) => {
    const index = str.toLowerCase().indexOf(searchTerm.toLowerCase());
    return index === -1 ? Infinity : index;
  };

  ingredientSuggestions.value = allIngredients.value.reduce((acc, ingredient) => {
    if (ingredient.name.toLowerCase().includes(query) && !selectedNames.has(ingredient.name.toLowerCase())) {
      acc.push({
        ...ingredient,
        score: getMatchScore(ingredient.name, ingredientSearchQuery.value),
      });
    }
    return acc;
  }, [])
  .sort((a, b) => a.score - b.score)
  .slice(0, 10);
};

// Select an ingredient from suggestions
const selectIngredient = (ingredient) => {
  selectedIngredient.value = ingredient;
  ingredientSearchQuery.value = '';
  ingredientSuggestions.value = [];
  ingredientMeasurement.value = ingredient.measurements[0];
};
// Add item to current shopping list
const addItem = async () => {
  if (!selectedIngredient.value || !ingredientAmount.value || !ingredientMeasurement.value) {
    itemError.value = 'Please provide valid ingredient details.';
    return;
  }

  const newItem = {
    selected: selectedIngredient.value,
    amount: parseFloat(ingredientAmount.value),
    measurement: ingredientMeasurement.value,
    checked: false,
  };

  currentList.value.items.unshift(newItem);

  

  // Reset inputs
  selectedIngredient.value = null;
  ingredientAmount.value = 1;
  ingredientMeasurement.value = null;
  itemError.value = '';
  comparisonsVisible.value = false;
  supermarketComparisons.value = {};
  // Update backend
  await updateListItem('add', newItem);
};
  
// Remove item from current shopping list
const removeItem = async (index) => {
  const item = currentListItems.value[index];
  comparisonsVisible.value = false;
  currentListItems.value.splice(index, 1);
  supermarketComparisons.value = {};
  await updateListItem('remove', item);
  
  
};
  
// Update item in backend
const updateListItem = async (action, item) => {
  try {
    await axios.post(`/api/shopping_lists/${selectedListId.value}/update_items/`, {
      ingredient_id: item.selected.id,
      measurement_id: item.measurement.id,
      amount: item.amount,
      list_type: 'shopping',
      list_id: selectedListId.value,
      action,
    });
  } catch (error) {
    console.error(`Error ${action}ing item:`, error);
  }
};
  
// New state variables
const shoppingList = ref({});
const showResults = ref(false);
  
// Update price comparisons
const updateComparisons = async () => {
  isLoading.value = true;
  comparisonsVisible.value = true;
  window.scrollTo(0, 0);

  const ingredients = currentListItems.value.map(item => ({
    id: item.selected.id,
    measurement_id: item.measurement.id,
    amount: item.amount
  }));

  try {
    const response = await axios.post('/api/ingredients/submit/', {
      ingredients,
      stores: ["Tesco", "Aldi", "MandS", "Sainsburys"]
    });

    shoppingList.value = response.data.shoppingList;
    showResults.value = true;
  } catch (error) {
    console.error('Error submitting ingredients:', error);
    // Handle submission error (e.g., show an error message)
  } finally {
    isLoading.value = false;
  }
};
const sortedSupermarkets = computed(() => {
  let supermarkets = Object.keys(shoppingList.value);
  
  let supermarketData = supermarkets.map(supermarket => ({
    name: supermarket,
    total_spend: shoppingList.value[supermarket].total_spend,
    missing_ingredients: shoppingList.value[supermarket].missing_ingredients
  }));

  let availableSupermarkets = supermarketData.filter(s => s.missing_ingredients.length === 0);
  let missingSupermarkets = supermarketData.filter(s => s.missing_ingredients.length > 0);

  availableSupermarkets.sort((a, b) => a.total_spend - b.total_spend);
  missingSupermarkets.sort((a, b) => a.total_spend - b.total_spend);

  return availableSupermarkets.concat(missingSupermarkets);
});


  
// Format price
const formatPrice = (price) => {
  return `£${price.toFixed(2)}`;
};
  </script>
  <style>
  /* Custom styles for smooth transitions */
  @media (min-width: 1024px) {
    .flex-1 {
      transition: transform 0.5s;
    }
  }
  </style>