<template>
    <div class="h-full flex flex-col md:flex-row justify-start md:justify-between z-50 md:items-center">
      <router-link to="/" class="flex flex-col md:flex-row items-center space-y-1 mb-6 md:mb-0 p-2" @click="emitLinkClicked">
        <img src="../assets/logo4.png" alt="Logo" class="h-12 md:h-16">
        <h2 class="text-2xl font-medium text-white">Meal Matcher</h2>
      </router-link>
  
      <nav>
        <ul class="space-y-4 md:space-y-0 md:space-x-2 pr-4 md:pr-0 text-sm md:text-md 2xl:text-lg md:flex md:w-max md:justify-between">
          <li v-for="link in navLinks" :key="link.to">
            <router-link 
              :to="link.to" 
              class="block py-2 px-4 md: px-2 md:pl-1 md:pr-1 items-center text-sm md:text-sm 2xl:text-lg font-light hover:bg-gray-100 hover:text-interactive-hover  transition-colors flex space-x-1 rounded-r-full md:rounded-full"
              :class="{ 'text-interactive font-semibold bg-white border border-1 border-gray-700  hover:bg-white hover:text-interactive shadow-inner': $route.path === link.to, 'font-light text-white border border-1 border-[#3a7160] ': $route.path !== link.to }"
              @click="emitLinkClicked"
            >
            <svg class="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <!-- Meal Planner -->
        <path v-if="link.text === 'Meal Planner'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
        <!-- Shopping List -->
        <path v-if="link.text === 'Shopping List'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path>
        <!-- Use Leftovers -->
        <path v-if="link.text === 'Use Leftovers'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
        <!-- Community -->
        <path v-if="link.text === 'Community'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path>
        <!-- Recipes -->
        <path v-if="link.text === 'Recipes'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"></path>
        <!-- About -->
        <path v-if="link.text === 'About'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
      </svg>
        {{ link.text }}
            </router-link>
          </li>
        </ul>
      </nav>
  
      <div class=" p-3 md:flex md:space-x-3">
        <template v-if="!authenticated" class="md:flex md:space-x-3">
          <router-link 
            to="/login" 
            class="block w-full text-center bg-gray-600   border border-1 border-gray-700 text-white py-2 px-4 rounded-full mb-4 md:mb-0 hover:bg-opacity-90 transition-colors"
            @click="emitLinkClicked"
          >
            Log in
          </router-link>
          <router-link 
            to="/signup" 
            class="block w-full text-center bg-interactive border border-1 border-gray-700 text-white py-2 px-4 rounded-full hover:bg-interactive-hover transition-colors"
            @click="emitLinkClicked"
          >
            Get started
          </router-link>
        </template>
        <template v-else class="md:flex md:space-x-3">
          <router-link 
            to="/profile" 
            class="flex items-center justify-center w-full md:max-w-32 truncate md:min-w-32 text-center text-gray-800 bg-gray-100 border border-1 border-gray-800 py-2 px-4 rounded-full mb-4 md:mb-0 hover:bg-opacity-90 transition-colors"
            @click="emitLinkClicked"
          >
            <ion-icon name="person-circle-outline" class="text-2xl mr-2"></ion-icon>
            {{ user.username }}
          </router-link>
          <!--<div class="flex items-center justify-center space-x-2 mb-4">
            <span :class="{ 'font-semibold': isPremiumUser }">{{ user.meal_tokens }}</span>
            <img :src="tokenImage" :class="{ 'h-6 w-6': isPremiumUser, 'h-5 w-5': !isPremiumUser }">
            <transition name="float-fade">
              <div v-if="showAnimation" class="absolute text-red-500 text-sm">-1</div>
            </transition>
          </div>-->
          <button 
            @click="logout" 
            class="block w-full text-center bg-gray-600 text-white py-2 md:max-w-32 truncate md:min-w-32  border border-1 border-gray-800 px-4 rounded-full hover:bg-opacity-90 transition-colors"
          >
            Logout
          </button>
        </template>
        
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, computed, watch } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  
  const store = useStore();
  const router = useRouter();
  const showAnimation = ref(false);
  
  const authenticated = computed(() => store.state.authenticated);
  const user = computed(() => store.state.user);
  const isPremiumUser = computed(() => store.getters.isPremiumUser);

  const navLinks = [
    { to: '/uk-meal-planner', text: 'Meal Planner' },
    { to: '/shopping-list', text: 'Shopping List' },
    { to: '/leftovers', text: 'Use Leftovers' },
   // { to: '/community', text: 'Community'},
    { to: '/recipes', text: 'Recipes' },
    { to: '/about', text: 'About' },
  ];
  
  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    store.dispatch('logout');
    router.push('/');
    emitLinkClicked();
  };
  
  const emitLinkClicked = () => {
    emit('linkClicked');
  };
  
  const emit = defineEmits(['linkClicked']);
  
  // Watch for changes in meal tokens
  watch(() => user.value.meal_tokens, (newValue, oldValue) => {
    if (newValue < oldValue) {
      showAnimation.value = true;
      setTimeout(() => {
        showAnimation.value = false;
      }, 500);
    }
  });
  </script>
  
  <style scoped>
  .float-fade-enter-active, .float-fade-leave-active {
    transition: all 0.5s ease;
  }
  .float-fade-enter-from, .float-fade-leave-to {
    opacity: 0;
    transform: translateY(-20px);
  }
  </style>