<template>
  <div class="min-h-screen bg-blue-50">
    <!-- Header -->
    <header class="bg-white shadow-md pt-2">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-1 flex justify-between items-center">
        <h1 class="text-xl font-semibold text-gray-900">Community Hub</h1>
        <div v-if="authenticated" class="flex items-center space-x-4">
          <button
            @click="openCreatePostModal"
            class="bg-interactive text-white font-medium rounded-full px-6 py-2 hover:bg-blue-700 transition duration-300 flex items-center"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
            </svg>
            Create Post
          </button>
        </div>
      </div>
    </header>

    <!-- Main Content -->
    <main class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div class="flex flex-col">

        <!-- Main Feed -->
        <div class=" space-y-6">
          <div v-if="posts.length === 0" class="text-center text-gray-500">
            No posts yet.
          </div>
          <div v-else v-for="post in posts" @click="viewPostDetails(post.id)" :key="post.id" class="bg-white shadow-md rounded-lg overflow-hidden cursor-pointer">
            <div class="px-3 py-2">
              
              <h2 class="text-xl font-semibold">{{ post.title }}</h2>
              <div class="flex items-center space-x-2 mb-1">
                  <h3 class="text-sm font-medium text-gray-900">{{ post.author.username }}</h3>
                  <p>-</p>  
                  <p class="text-sm text-gray-500">{{ formatDate(post.created_at) }}</p>
              </div>
              <p class="text-gray-700 mb-4">{{ post.content }}</p>
              <div class="flex items-center justify-between">
                <div class="flex items-center space-x-4">
                  <button
                    @click.stop="upvotePost(post.id)"
                    class="flex items-center text-gray-500 hover:text-blue-600 transition duration-300"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                    </svg>
                    {{ post.upvotes_count ? post.upvotes_count : 0 }}
                  </button>
                  <button
                    @click="viewPostDetails(post.id)"
                    class="flex items-center text-gray-500 hover:text-blue-600 transition duration-300"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clip-rule="evenodd" />
                    </svg>
                    {{ post.comments ? post.comments.length : 0 }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="paginationInfo.next" class="text-center">
            <button @click="loadMorePosts" class="bg-blue-600 text-white font-medium rounded-full px-6 py-2 hover:bg-blue-700 transition duration-300">
              Load More
            </button>
          </div>
        </div>
      </div>
    </main>
  </div>

  <!-- Create Post Modal -->
  <div v-if="showCreatePostModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div class="bg-white rounded-lg p-8 w-full max-w-lg">
      <h2 class="text-2xl font-bold text-gray-900 mb-6">Create a New Post</h2>
      <input
        v-model="newPost.title"
        type="text"
        placeholder="Title"
        class="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
      />
      <textarea
        v-model="newPost.content"
        placeholder="What's on your mind?"
        class="w-full p-3 mb-6 border border-gray-300 rounded-lg h-40 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
      ></textarea>
      <div class="flex justify-end space-x-4">
        <button
          @click="closeCreatePostModal"
          class="px-6 py-2 text-gray-600 hover:text-gray-800 font-medium"
        >
          Cancel
        </button>
        <button
          @click="submitPost"
          class="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 font-medium"
        >
          Post
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

import axios from 'axios';
const router = useRouter(); // Add this line to get the router instance

const store = useStore();
const authenticated = computed(() => store.state.authenticated);

const posts = ref([]);
const showCreatePostModal = ref(false);
const newPost = ref({ title: '', content: '' });
const paginationInfo = ref({ count: 0, next: null, previous: null });

const communityStats = ref({
  memberCount: 0,
  postsToday: 0
});

const topContributors = ref([]);

onMounted(async () => {
  window.scrollTo(0, 0);
  await fetchPosts();
  await fetchCommunityStats();
  await fetchTopContributors();
});

const fetchPosts = async () => {
  try {
    const response = await axios.get('/api/community/posts/');

    if (response.data.results.length === 0) {
      console.log('No posts yet');
    } else {
      posts.value = response.data.results;
      paginationInfo.value = {
        count: response.data.count,
        next: response.data.next,
        previous: response.data.previous
      };
    }
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
};

const loadMorePosts = async () => {
  if (paginationInfo.value.next) {
    try {
      const response = await axios.get(paginationInfo.value.next);
      posts.value = [...posts.value, ...response.data.results];
      paginationInfo.value = {
        count: response.data.count,
        next: response.data.next,
        previous: response.data.previous
      };
    } catch (error) {
      console.error('Error loading more posts:', error);
    }
  }
};

const fetchCommunityStats = async () => {
  // This is a placeholder. You'll need to implement an API endpoint for this.
  communityStats.value = {
    memberCount: 10234,
    postsToday: 156
  };
};

const fetchTopContributors = async () => {
  // This is a placeholder. You'll need to implement an API endpoint for this.
  topContributors.value = [
    { id: 1, username: 'Alice' },
    { id: 2, username: 'Bob' },
    { id: 3, username: 'Charlie' },
  ];
};

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

const openCreatePostModal = () => {
  showCreatePostModal.value = true;
};

const closeCreatePostModal = () => {
  showCreatePostModal.value = false;
  newPost.value = { title: '', content: '' };
};

const submitPost = async () => {
  try {
    await axios.post('/api/community/posts/', newPost.value);
    await fetchPosts();
    closeCreatePostModal();
  } catch (error) {
    console.error('Error creating post:', error);
  }
};

const upvotePost = (postId) => {
  posts.value = posts.value.map(post => {
    if (post.id === postId) {
      post.upvotes_count = post.upvotes_count ? post.upvotes_count + 1 : 1;
    }
    return post;
  });
  try {
     axios.post(`/api/community/posts/${postId}/upvote/`);
  } catch (error) {
    console.error('Error upvoting post:', error);
  }
};


const showComments = (postId) => {
  // Navigate to post details/comments page
  // You'll need to implement this based on your routing setup
  console.log('Showing comments for post:', postId);
};

const viewPostDetails = (postId) => {
  router.push({ name: 'ViewPost', params: { id: postId } });
};
</script>