<template>
  <div class="min-h-screen flex items-center justify-center bg-[#f6fbfe] pt-20">
    <div class="w-full max-w-3xl mx-auto p-5 text-center">
      <h1 class="text-3xl mb-6 text-p-t">Payment Successful!</h1>
      <p class="mb-4">Thank you for your purchase. Your subscription is now active.</p>
      <p v-if="loading">Updating your account...</p>
      <p v-if="error" class="text-red-500">{{ error }}</p>
      <router-link 
        to="/uk-meal-planner" 
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Go to Dashboard
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PaymentSuccessful',
  data() {
    return {
      loading: true,
      error: null
    }
  },
  created() {
    this.updateUserState();
  },
  mounted() {
  },
  methods: {
    async updateUserState() {
      console.log('Updating user state');
      this.loading = true;
      this.error = null;
      try {
        const response = await axios.get('/api/get_user_info/');
        localStorage.setItem('user', JSON.stringify(response.data.user));
        // Dispatch login action with user and roles
        this.$store.dispatch('update', response.data.user);
        this.loading = false;
      } catch (error) {
        console.error('Error updating user state:', error);
        this.error = 'Failed to update account information. Please refresh the page or contact support.';
        this.loading = false;
      }
    }
  }
}
</script>