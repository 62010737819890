<template>
    <div class=" min-h-screen bg-[#f8f9fa] w-full flex justify-center relative">
        <!-- Fixed Scrolling Banner -->
        <div class="fixed top-14 md:top-0 left-0 right-0 bg-white border-b border-gray-600 overflow-hidden z-20 shadow-sm">
            <div class="scrolling-banner py-2 text-gray-800">
                <span class="inline-block">
                    🎉 Limited Time Offer: Get 5 additional days trial or active for every approved recipe you add! 🎉
                </span>
            </div>
        </div>
        <RecipeList :useIngredients="false" class="pt-12" />
    </div>
</template>

<script setup>
import { useStore } from 'vuex';
import RecipeList from './Recipes-components/RecipeList.vue';


const store = useStore();
</script>

<style scoped>
.scrolling-banner {
  white-space: nowrap;
  animation: scroll 20s linear infinite;
}

@keyframes scroll {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

/* Pause animation on hover */
.scrolling-banner:hover {
  animation-play-state: paused;
}
</style>