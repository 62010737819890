import { createRouter, createWebHistory } from 'vue-router';
import Home from './components/Home.vue';
import Controller from './components/Controller.vue';
import Recipes from './components/Recipes.vue';
import About from './components/About.vue';
import Login from './components/Login.vue';
import Signup from './components/Signup.vue';
import SelectPlan from './components/SelectPlan.vue';
import Community from './components/Community.vue';
import Profile from './components/Profile.vue';
import HowToUse from './components/HowToUse.vue';
import ForgotPassword from './components/ForgotPassword.vue';
import ResetPassword from './components/ResetPassword.vue';
import PaymentSuccessful from './components/PaymentSuccessful.vue';
import ViewPost from './components/Community/viewPost.vue';
import ShoppingList from './components/shopping.vue';
import Leftovers from './components/Leftovers.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/uk-meal-planner', component: Controller },
  { path: '/recipes', component: Recipes },
  { path: '/about', component: About },
  { path: '/leftovers', component: Leftovers },
  { path: '/login', component: Login },
  { path: '/signup', component: Signup },
  { path: '/profile', component: Profile },
  { path: '/forgot-password', component: ForgotPassword },
  { path: '/reset-password/:uid/:token', component: ResetPassword },
  { path: '/how-to-use', component: HowToUse },
  { path: '/community', component: Community },
  { path: '/payment-successful', component: PaymentSuccessful },
  { path: '/shopping-list', component: ShoppingList },
  {
    path: '/posts/:id',
    name: 'ViewPost',
    component: ViewPost
  },
  {
    path: '/select-plan',
    name: 'SelectPlan',
    component: SelectPlan,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('access_token')) {
      next({ path: '/login' });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
