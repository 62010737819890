<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">{{ ingredient.name }}</h3>
    </div>
    <!-- Overview Section -->
    <div v-if="overview" class="mb-6 p-4 bg-gray-100 rounded-lg overflow-y-auto max-h-60">
      <h4 class="text-lg font-semibold mb-2">Overview</h4>
      
      <!-- Missing Supermarkets -->
      <div v-if="overview.missing_supermarkets.length" class="mb-3">
        <h5 class="font-medium text-gray-700">Missing Supermarkets:</h5>
        <ul class="list-disc list-inside text-sm text-gray-600">
          <li v-for="supermarket in overview.missing_supermarkets" :key="supermarket">
            {{ supermarket }}
          </li>
        </ul>
      </div>
      
      <!-- Anomalies -->
      <div v-if="overview.anomalies.length">
        <h5 class="font-medium text-gray-700">Anomalies:</h5>
        <ul class="space-y-1">
          <li v-for="anomaly in overview.anomalies" :key="`${anomaly.supermarket}-${anomaly.field}-${anomaly.supermarket_item_name}`" class="text-sm">
            <span class="font-medium">{{ anomaly.supermarket }} - {{ anomaly.supermarket_item_name }}:</span>
            <span :class="{'text-yellow-600': anomaly.type === 'outlier', 'text-red-600': anomaly.type === 'zero'}">
              {{ anomaly.field }} ({{ anomaly.value }}) - {{ anomaly.type }}
            </span>
            <span class="ml-2 text-gray-500">
              {{ anomaly.use_default ? '(Using default)' : '(Custom value)' }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="border-t border-gray-400 px-4 py-5 sm:p-0">
      <div class="flex flex-wrap">
        <!-- Left column: Editable ingredient details -->
        <div class="w-full md:w-1/2 pr-4">
          <dl>
            <div v-for="(value, key) in ingredientDetails" :key="key" class="py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-6">
              <dt class="text-sm font-medium text-gray-700 sm:col-span-2">{{ formatLabel(key) }}</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 ">
                <input 
                  type="number" 
                  v-model="ingredient[key]" 
                  class="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  step="0.01"
                />
              </dd>
            </div>
          </dl>
        </div>
        
        <!-- Right column: Measurements and Diets -->
        <div class="w-full md:w-1/2 pl-4">
          <!-- Measurements -->
          <div class="mb-6">
            <h4 class="text-sm font-medium text-gray-500 mb-2">Measurements</h4>
            <div class="flex flex-wrap gap-2">
              <button
                v-for="measurement in measurements"
                :key="measurement.id"
                @click="toggleMeasurement(measurement)"
                :class="[
                  'px-3 py-1 rounded-full text-sm font-medium',
                  ingredient.measurements.some(m => m.id === measurement.id)
                    ? 'bg-orange-200 text-orange-800'
                    : 'bg-gray-200 text-gray-800'
                ]"
              >
                {{ measurement.name }}
              </button>
            </div>
          </div>

          <!-- Diets -->
          <div>
            <h4 class="text-sm font-medium text-gray-500 mb-2">Diets</h4>
            <div class="flex flex-wrap gap-2">
              <button
                v-for="diet in diets"
                :key="diet.id"
                @click="toggleDiet(diet)"
                :class="[
                  'px-3 py-1 rounded-full text-sm font-medium',
                  ingredient.diets.some(d => d.id === diet.id)
                    ? 'bg-orange-200 text-orange-800'
                    : 'bg-gray-200 text-gray-800'
                ]"
              >
                {{ diet.name }}
              </button>
            </div>
          </div>
          <label for="can_be_loose">Can be sold loose:</label>
          <input type="checkbox" id="can_be_loose" v-model="ingredient.can_be_loose">
        </div>
      </div>

      <!-- Supermarket Items -->
      <div class="mt-6 sm:px-6">
        <h4 class="text-sm font-medium text-gray-500 mb-4">Supermarket Items</h4>
        <div class="grid grid-cols-1 gap-4">
          <div v-for="(items, supermarket) in groupedSupermarketItems" :key="supermarket" class="mb-4">
            <h5 class="font-medium mb-2 text-lg">{{ supermarket }}</h5>
            <div class="space-y-2">
              <div v-for="item in items" :key="item.id" class="border rounded-lg overflow-hidden">
                <div 
                  @click="toggleItemDetails(item.id)" 
                  class="p-2 cursor-pointer hover:bg-gray-50 flex justify-between items-center"
                >
                  <span class="font-medium">{{ item.supermarket_item.name }}</span>
                  <svg class="w-5 h-5 transform transition-transform duration-200" :class="{ 'rotate-180': expandedItems.includes(item.id) }" fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                  </svg>
                </div>
                <div v-if="expandedItems.includes(item.id)" class="p-2 bg-gray-50 text-sm">
                  <div class="grid grid-cols-3 gap-2">
                    <div>
                      <h6 class="font-medium mb-1">General Information</h6>
                      <div v-for="(value, key) in formatSupermarketItem(item).general" :key="key" class="flex justify-between items-center mb-1">
                        <span class="text-gray-600 mr-2">{{ key }}:</span>
                        <input 
                          :value="item[getItemKey(key)]"
                          @input="updateItemValue(item, getItemKey(key), $event.target.value)"
                          class="w-24 px-1 py-0.5 text-sm border rounded"
                          :type="['Price', 'Total Weight', 'Serving Size', 'Serving Price', 'Confidence'].includes(key) ? 'number' : 'text'"
                          :step="['Price', 'Serving Price'].includes(key) ? '0.01' : '1'"
                        />
                      </div>
                    </div>
                    <div>
                      <h6 class="font-medium mb-1">Per Serving</h6>
                      <div v-for="(value, key) in formatSupermarketItem(item).perServing" :key="key" class="flex justify-between items-center mb-1">
                        <span class="text-gray-600 mr-2">{{ key }}:</span>
                        <input 
                          :value="item[getItemKey(key, 'per_serving')]"
                          @input="updateItemValue(item, getItemKey(key, 'per_serving'), $event.target.value)"
                          class="w-24 px-1 py-0.5 text-sm border rounded"
                          type="number"
                          :step="key === 'Calories' ? '1' : '0.1'"
                        />
                      </div>
                    </div>
                    <div>
                      <h6 class="font-medium mb-1">Per 100g</h6>
                      <div v-for="(value, key) in formatSupermarketItem(item).per100g" :key="key" class="flex justify-between items-center mb-1">
                        <span class="text-gray-600 mr-2">{{ key }}:</span>
                        <input 
                          :value="item[getItemKey(key, 'per_100g')]"
                          @input="updateItemValue(item, getItemKey(key, 'per_100g'), $event.target.value)"
                          class="w-24 px-1 py-0.5 text-sm border rounded"
                          type="number"
                          :step="key === 'Calories' ? '1' : '0.1'"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mt-2">
                    <label class="inline-flex items-center">
                      <input type="checkbox" v-model="item.use_default" class="form-checkbox h-4 w-4 text-blue-600">
                      <span class="ml-2 text-gray-700">Use as default</span>
                    </label>
                  </div>
                  <div class="p-2 bg-gray-50 flex justify-between items-center">
                    <button 
                      @click="deleteItem(item)"
                      class="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
      <button
        @click="saveChanges"
        class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Save Changes
      </button>
      <button
        @click="$emit('close')"
        class="ml-3 inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Cancel
      </button>
    </div>
    <div v-if="message" class="px-4 py-3 text-center" :class="{ 'text-green-600': !error, 'text-red-600': error }">
      {{ message }}
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import axios from 'axios';

const props = defineProps(['ingredient', 'measurements', 'diets', 'overview']);
const emit = defineEmits(['update', 'close']);

const ingredient = ref({ ...props.ingredient });
const ingredientSupermarketItems = ref([]);
const message = ref('');
const error = ref(false);
const expandedItems = ref([]);
const ingredientDetails = computed(() => ({
  default_serving_size: ingredient.value.default_serving_size,
  default_calories_per_serving: ingredient.value.default_calories_per_serving,
  default_fat_per_serving: ingredient.value.default_fat_per_serving,
  default_saturated_fat_per_serving: ingredient.value.default_saturated_fat_per_serving,
  default_carbohydrate_per_serving: ingredient.value.default_carbohydrate_per_serving,
  default_sugar_per_serving: ingredient.value.default_sugar_per_serving,
  default_fibre_per_serving: ingredient.value.default_fibre_per_serving,
  default_protein_per_serving: ingredient.value.default_protein_per_serving,
  default_salt_per_serving: ingredient.value.default_salt_per_serving,
  tablespoon: ingredient.value.tablespoon,
}));

const toggleItemDetails = (itemId) => {
  const index = expandedItems.value.indexOf(itemId);
  if (index > -1) {
    expandedItems.value.splice(index, 1);
  } else {
    expandedItems.value.push(itemId);
  }
};
const groupedSupermarketItems = computed(() => {
  const grouped = {};
  ingredientSupermarketItems.value.forEach(item => {
    const supermarket = item.supermarket_item.supermarket.name;
    if (!grouped[supermarket]) {
      grouped[supermarket] = [];
    }
    grouped[supermarket].push(item);
  });
  return grouped;
});

const formatLabel = (key) => {
  return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

const deleteItem = (item) => {
  if (confirm(`Are you sure you want to delete ${item.supermarket_item.name}?`)) {
    const index = ingredientSupermarketItems.value.findIndex(i => i.id === item.id);
    if (index > -1) {
      ingredientSupermarketItems.value.splice(index, 1);
    }
  }
};
const updateItemValue = (item, key, value) => {
  if (value === '') {
    item[key] = null;
  } else if (key === 'best_unit') {
    item[key] = value;
  } else {
    item[key] = parseFloat(value);
  }
};

const formatSupermarketItem = (item) => {
  const formatNumber = (value, decimals = 2) => {
    const num = parseFloat(value);
    return isNaN(num) ? '0' : num.toFixed(decimals);
  };

  return {
    general: {
      'Price': `£${formatNumber(item.price)}`,
      'Total Weight': `${formatNumber(item.total_weight, 1)}g`,
      'Serving Size': `${formatNumber(item.serving_size, 1)}g`,
      'Serving Price': `£${formatNumber(item.serving_price)}`,
      'number of Servings': item.number_of_servings,
      'Best Unit': item.best_unit,
      'Confidence': `${formatNumber(item.confidence, 0)}%`
    },
    perServing: {
      'Energy': `${formatNumber(item.energy_per_serving, 1)} kJ`,
      'Calories': `${formatNumber(item.calories_per_serving, 0)} kcal`,
      'Fat': `${formatNumber(item.fat_per_serving, 1)}g`,
      'Saturated Fat': `${formatNumber(item.saturated_fat_per_serving, 1)}g`,
      'Carbohydrate': `${formatNumber(item.carbohydrate_per_serving, 1)}g`,
      'Sugar': `${formatNumber(item.sugar_per_serving, 1)}g`,
      'Fibre': `${formatNumber(item.fibre_per_serving, 1)}g`,
      'Protein': `${formatNumber(item.protein_per_serving, 1)}g`,
      'Salt': `${formatNumber(item.salt_per_serving, 2)}g`
    },
    per100g: {
      'Energy': `${formatNumber(item.energy_per_100g, 1)} kJ`,
      'Calories': `${formatNumber(item.calories_per_100g, 0)} kcal`,
      'Fat': `${formatNumber(item.fat_per_100g, 1)}g`,
      'Saturated Fat': `${formatNumber(item.saturated_fat_per_100g, 1)}g`,
      'Carbohydrate': `${formatNumber(item.carbohydrate_per_100g, 1)}g`,
      'Sugar': `${formatNumber(item.sugar_per_100g, 1)}g`,
      'Fibre': `${formatNumber(item.fibre_per_100g, 1)}g`,
      'Protein': `${formatNumber(item.protein_per_100g, 1)}g`,
      'Salt': `${formatNumber(item.salt_per_100g, 2)}g`
    }
  };
};

const getItemKey = (key, suffix = '') => {
  const baseKey = key.toLowerCase().replace(/ /g, '_').replace(/[^a-z0-9_]/g, '');
  if (baseKey === 'of_servings') return 'number_of_servings';
  return suffix ? `${baseKey}_${suffix}` : baseKey;
};

const toggleMeasurement = (measurement) => {
  const index = ingredient.value.measurements.findIndex(m => m.id === measurement.id);
  if (index > -1) {
    ingredient.value.measurements.splice(index, 1);
  } else {
    ingredient.value.measurements.push(measurement);
  }
};

const toggleDiet = (diet) => {
  const index = ingredient.value.diets.findIndex(d => d.id === diet.id);
  if (index > -1) {
    ingredient.value.diets.splice(index, 1);
  } else {
    ingredient.value.diets.push(diet);
  }
};

const loadSupermarketItems = async () => {
  try {
    const response = await axios.get(`/api/ingredients/${ingredient.value.id}/supermarket_items/`);
    ingredientSupermarketItems.value = response.data;
     // Set up watchers for each supermarket item
     ingredientSupermarketItems.value.forEach(item => {
      // Ensure initial values
      if (item.number_of_servings === undefined) item.number_of_servings = 1;
      if (item.serving_size === undefined) item.serving_size = 0;

      watch(
        () => [item.total_weight, item.number_of_servings],
        ([newWeight, newServings]) => {
          if (newServings > 0) {
            item.serving_size = newWeight / newServings;
            item.serving_price = item.price / newServings;
            const multiple = item.serving_size / 100
            item.calories_per_serving = item.calories_per_100g * multiple;
            item.fat_per_serving = item.fat_per_100g * multiple;
            item.saturated_fat_per_serving = item.saturated_fat_per_100g * multiple;
            item.carbohydrate_per_serving = item.carbohydrate_per_100g * multiple;
            item.sugar_per_serving = item.sugar_per_100g * multiple;
            item.fibre_per_serving = item.fibre_per_100g * multiple;
            item.protein_per_serving = item.protein_per_100g * multiple;
            item.salt_per_serving = item.salt_per_100g * multiple;

          } else {
            item.serving_size = 0;
            item.calories_per_serving = 0;
            item.fat_per_serving = 0;
            item.saturated_fat_per_serving = 0;
            item.carbohydrate_per_serving = 0;
            item.sugar_per_serving = 0;
            item.fibre_per_serving = 0;
            item.protein_per_serving = 0;
            item.salt_per_serving = 0;

          }
        },
        { immediate: true }
      );
    });
  } catch (err) {
    console.error('Error loading supermarket items:', err);
    message.value = 'Failed to load supermarket items. Please try again.';
    error.value = true;
  }
};


const saveChanges = async () => {
  try {
    const payload = {
      ...ingredient.value,
      supermarket_items: ingredientSupermarketItems.value
    };
    // Remove the thumbnail field from the payload
    delete payload.thumbnail;
    const response = await axios.post(`/api/ingredients/${ingredient.value.id}/update_with_items/`, payload);

    if (response.status === 200) {
      message.value = 'All changes saved successfully.';
      error.value = false;
      emit('update', response.data);
      
      // Update local data with the response
      ingredient.value = response.data;
      ingredientSupermarketItems.value = response.data.supermarket_items;

      setTimeout(() => {
        message.value = '';
        emit('close');
      }, 2000);
    } else {
      throw new Error('Unexpected response status');
    }
  } catch (err) {
    console.error('Error updating data:', err);
    message.value = err.response?.data?.message || 'Failed to update. Please try again.';
    error.value = true;
  }
};

onMounted(loadSupermarketItems);
</script>