<template>
  <div class="fixed inset-0 bg-gradient-to-br from-blue-50 to-purple-50 overflow-hidden">
    <div class="h-full w-full flex flex-col">
      <div class="flex-grow overflow-y-auto p-4 md:p-6 lg:p-8">
        <h2 class="text-2xl font-bold text-white mb-6">Ingredients</h2>
        <div class="bg-white rounded-lg shadow-lg p-4 mb-6">
          <div class="relative">
            <input 
              v-model="searchQuery" 
              @input="searchIngredients" 
              placeholder="Search ingredients..." 
              class="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
            <svg class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" width="20" height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </div>
        </div>
        <div v-if="loading" class="text-center text-white">
          Loading...
        </div>
        <div v-else-if="error" class="text-center text-red-500">
          {{ error }}
        </div>
        <div v-else class="space-y-6">
          <div 
            v-for="(category, categoryId) in filteredCategorizedIngredients" 
            :key="categoryId" 
            class="bg-white rounded-lg shadow-lg overflow-hidden"
          >
            <h3 class="text-lg font-semibold bg-blue-100 text-blue-800 p-4">{{ categoryId }}</h3>
            <div class="divide-y divide-gray-200">
              <details v-for="(subcategory, subcategoryName) in category" :key="subcategoryName" class="group">
                <summary class="flex items-center justify-between p-4 cursor-pointer bg-gray-50 hover:bg-gray-100 transition-colors">
                  <span class="font-medium text-gray-700">{{ subcategoryName }}</span>
                  <span class="transform group-open:rotate-180 transition-transform">
                    <svg class="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
                      <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </span>
                </summary>
                <ul class="bg-white">
                  <li 
                    v-for="ingredient in subcategory" 
                    :key="ingredient.id"
                    @click="selectIngredient(ingredient)"
                    :class="ingredientOverview[ingredient.id]?.hasCritical ? 'bg-red-50' : ''"
                    class="p-4 cursor-pointer hover:bg-blue-50 transition-colors"
                  >
                    <div class="flex items-center justify-between">
                      <span>{{ ingredient.name }}</span>
                      <div v-if="ingredientOverview[ingredient.id]" class="flex space-x-2">
                        <span 
                          v-if="ingredientOverview[ingredient.id].missing_supermarkets.length" 
                          class="bg-green-100 text-green-800 px-2 py-1 rounded-full text-xs"
                        >
                          {{ ingredientOverview[ingredient.id].missing_supermarkets.length }} missing
                        </span>
                        <span 
                          v-if="ingredientOverview[ingredient.id].zero_anomalies.length" 
                          class="bg-red-100 text-red-800 px-2 py-1 rounded-full text-xs flex items-center space-x-1"
                        >
                          <svg class="w-4 h-4 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"/>
                          </svg>
                          <span>{{ ingredientOverview[ingredient.id].zero_anomalies.length }} Zero</span>
                        </span>
                        <span 
                          v-if="ingredientOverview[ingredient.id].outlier_anomalies.length" 
                          class="bg-yellow-100 text-yellow-800 px-2 py-1 rounded-full text-xs flex items-center space-x-1"
                        >
                          <svg class="w-4 h-4 text-yellow-500" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 2a8 8 0 100 16 8 8 0 000-16zm1 11H9v-2h2v2zm0-4H9V5h2v4z"/>
                          </svg>
                          <span>{{ ingredientOverview[ingredient.id].outlier_anomalies.length }} Outliers</span>
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </details>
            </div>
          </div>
        </div>
      </div>
      <div class="sticky bottom-0 w-full bg-white shadow-md p-4">
        <button 
          @click="$emit('close')" 
          class="w-full bg-red-500 hover:bg-red-600 text-white font-semibold py-3 rounded-lg transition-colors"
        >
          Close
        </button>
      </div>
    </div>

    <div v-if="selectedIngredient" class="fixed inset-0 top-0 w-full bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div class="bg-white rounded-lg shadow-xl w-full ml-52 max-w-6xl max-h-screen overflow-y-auto">
        <div class="p-6">
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-xl font-semibold">{{ selectedIngredient.name }}</h3>
            <button 
              @click="selectedIngredient = null"
              class="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <IngredientDetail 
            :ingredient="selectedIngredient"
            :diets="diets"
            :measurements="measurements"
            :overview="ingredientOverview[selectedIngredient.id]"
            @close="selectedIngredient = null"
            @update="updateIngredient"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import IngredientDetail from './IngredientDetail.vue';
import axios from 'axios';

const diets = [
  { id: 1, name: 'Keto' },
  { id: 2, name: 'Vegetarian' },
  { id: 3, name: 'Vegan' },
  { id: 4, name: 'Paleo' },
  { id: 5, name: 'Atkins' },
  { id: 6, name: 'Gluten-Free' },
  { id: 7, name: 'Dairy-Free' },
];
const measurements = [
  { id: 1, name: 'Grams' },
  { id: 3, name: 'Kilograms' },
  { id: 4, name: 'Litres' },
  { id: 5, name: 'Items' },
  { id: 6, name: 'Gram' },
  { id: 7, name: 'Teaspoon' },
  { id: 8, name: 'Tablespoon' },
  { id: 9, name: 'Pinch' },
  { id: 19, name: 'Milliliters' },
  { id: 20, name: "Clove"}
];

const props = defineProps({
  ingredients: Array,
});

const ingredients = ref(props.ingredients);
const searchQuery = ref('');
const selectedIngredient = ref(null);
const loading = ref(true);
const error = ref(null);
const ingredientOverview = ref({});

// Enhanced processing for anomaly types
const processIngredientOverview = (data) => {
  const overview = {};
  data.forEach(item => {
    const { ingredient_id, missing_supermarkets, anomalies } = item;
    const zero_anomalies = anomalies.filter(a => a.value === 0 && !a.use_default);
    const outlier_anomalies = anomalies.filter(a => a.value !== 0 && !a.use_default);
    overview[ingredient_id] = {
      missing_supermarkets,
      anomalies,
      zero_anomalies,
      outlier_anomalies,
      hasCritical: zero_anomalies.length > 0
    };
  });
  ingredientOverview.value = overview;
};

const fetchIngredientOverview = async () => {
  try {
    const response = await axios.get('/api/ingredients/missing_supermarkets_and_anomalies/');
    processIngredientOverview(response.data);
    loading.value = false;
  } catch (err) {
    error.value = 'Failed to fetch ingredient data. Please try again later.';
    loading.value = false;
  }
};

const searchIngredients = () => {
  // The filtering is now handled by the filteredCategorizedIngredients computed property
};

const selectIngredient = (ingredient) => {
  selectedIngredient.value = ingredient;
};

const updateIngredient = (updatedIngredient) => {
  const index = ingredients.value.findIndex(i => i.id === updatedIngredient.id);
  if (index !== -1) {
    ingredients.value[index] = updatedIngredient;
  }
  selectedIngredient.value = null;
};

const categorizedIngredients = computed(() => {
  const categorized = {};
  ingredients.value.forEach(ingredient => {
    if (!categorized[ingredient.category]) {
      categorized[ingredient.category] = {};
    }
    if (!categorized[ingredient.category][ingredient.subcategory.name]) {
      categorized[ingredient.category][ingredient.subcategory.name] = [];
    }
    categorized[ingredient.category][ingredient.subcategory.name].push(ingredient);
  });
  return categorized;
});

const filteredCategorizedIngredients = computed(() => {
  if (!searchQuery.value) {
    return categorizedIngredients.value;
  }

  const filtered = {};
  Object.entries(categorizedIngredients.value).forEach(([categoryId, category]) => {
    const filteredSubcategories = {};
    Object.entries(category).forEach(([subcategoryId, subcategory]) => {
      const filteredSubcategory = subcategory.filter(ingredient =>
        ingredient.name.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
      if (filteredSubcategory.length > 0) {
        filteredSubcategories[subcategoryId] = filteredSubcategory;
      }
    });
    if (Object.keys(filteredSubcategories).length > 0) {
      filtered[categoryId] = filteredSubcategories;
    }
  });
  return filtered;
});

// Watch for changes in props.ingredients and refetch data if necessary
watch(() => props.ingredients, (newIngredients) => {
  ingredients.value = newIngredients;
  ingredients.value.sort((a, b) => a.name.localeCompare(b.name));
});

onMounted(() => {
  ingredients.value.sort((a, b) => a.name.localeCompare(b.name));
  fetchIngredientOverview();
});
</script>