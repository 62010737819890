<template>
  <div class="w-full min-h-screen px-4 py-12 ">
    <h1 class="text-3xl font-medium text-gray-900 mb-8">User Profile</h1>
    
    <div v-if="!authenticated" class="bg-white shadow-lg rounded-lg p-8 text-center">
      <h2 class="text-2xl font-bold mb-4">Welcome to the Meal Planner</h2>
      <p class="text-gray-600 mb-6">To get started, please log in or sign up</p>
      <div class="space-x-4">
        <router-link to="/login" class="bg-blue-600 text-white font-semibold rounded-md px-6 py-2 hover:bg-blue-700 transition duration-300">Sign In</router-link>
        <router-link to="/signup" class="bg-green-600 text-white font-semibold rounded-md px-6 py-2 hover:bg-green-700 transition duration-300">Sign Up</router-link>
      </div>
    </div>

    <div v-else class="bg-white shadow-lg rounded-lg overflow-hidden">
      <div class="bg-gradient-to-r from-blue-600 to-blue-800 px-6 py-4">
        <h2 class="text-xl font-bold text-white">Account Overview</h2>
      </div>
      
      <div class="p-6 space-y-8">
        <div class="grid md:grid-cols-2 gap-8">
          <div class="bg-gray-50 p-6 rounded-lg">
            <h3 class="text-lg font-semibold text-gray-800 mb-4 flex items-center">
              <svg class="w-5 h-5 mr-2 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
              Personal Information
            </h3>
            <div class="space-y-3 text-gray-600">
              <p><span class="font-medium text-gray-700">Username:</span> {{ user.username }}</p>
              <p><span class="font-medium text-gray-700">Name:</span> {{ user.name || 'Not provided' }}</p>
              <p><span class="font-medium text-gray-700">Email:</span> {{ user.email }}</p>
              <p><span class="font-medium text-gray-700">Phone:</span> {{ user.phone || 'Not provided' }}</p>
            </div>
          </div>
          
          <div class="bg-gray-50 p-6 rounded-lg">
            <h3 class="text-lg font-semibold text-gray-800 mb-4 flex items-center">
              <svg class="w-5 h-5 mr-2 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path></svg>
              Account Details
            </h3>
            <div class="space-y-3 text-gray-600">
              <p>
                <span class="font-medium text-gray-700">Account Type: </span> 
                <span :class="user.is_premium ? 'text-green-600 font-semibold' : 'text-blue-600'">
                  {{ user.is_premium ? 'Premium' : 'Standard' }}
                </span>
              </p>
              <p><span class="font-medium text-gray-700">Meal Tokens:</span> {{ user.meal_tokens }}</p>
              <p><span class="font-medium text-gray-700">Plans Generated:</span> {{ totalMealPlans }}</p>
              <p><span class="font-medium text-gray-700">Member Since:</span> {{ formatDate(user.date_joined) }}</p>
            </div>
          </div>
        </div>
        
        <div class="border-t border-gray-200 pt-6">
          <h3 class="text-lg font-semibold text-gray-800 mb-4 flex items-center">
            <svg class="w-5 h-5 mr-2 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path></svg>
            Subscription Details
          </h3>
          <div v-if="subscriptionDetails" class="bg-gray-50 p-6 rounded-lg space-y-3">
            <p><span class="font-medium text-gray-700">Current Status:</span>
              <span :class="subscriptionStatusClass" class="font-semibold">{{ subscriptionStatus }}</span>
            </p>
            <p><span class="font-medium text-gray-700">Plan:</span> {{ subscriptionDetails.plan_name }}</p>
            <p><span class="font-medium text-gray-700">Next Billing Date:</span> {{ formatDate(subscriptionDetails.next_billing_date) }}</p>
            <p><span class="font-medium text-gray-700">Amount Due:</span> {{ formatCurrency(subscriptionDetails.amount_due) }}</p>
            <p v-if="subscriptionDetails.trial_end"><span class="font-medium text-gray-700">Trial Ends:</span> {{ formatDate(subscriptionDetails.trial_end) }}</p>
          </div>
          <div v-else-if="subscriptionStatus === 'Free Plan'" class="bg-gray-50 p-6 rounded-lg">
            <p class="text-gray-600">You are currently on the free plan. Upgrade to access premium features!</p>
          </div>
          <button 
            @click="manageSubscription" 
            class="mt-6 bg-blue-600 text-white font-semibold px-6 py-2 rounded-md hover:bg-blue-700 transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            {{ subscriptionStatus === 'Free Plan' ? 'Upgrade to Premium' : 'Manage Subscription' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import axios from 'axios';

const store = useStore();
const router = useRouter();
const authenticated = computed(() => store.state.authenticated);
const subscription = computed(() => store.getters.isSubscribed);

const user = ref({});
const totalMealPlans = ref(0);
const subscriptionDetails = ref(null);


onMounted(async () => {
  // Fetch user data from Vuex store or localStorage
  const storedUser = JSON.parse(localStorage.getItem('user'));
  if (storedUser) {
    user.value = storedUser;
  }
  
  if (authenticated.value) {
    await fetchSubscriptionDetails();
  }
});
const fetchSubscriptionDetails = async () => {
  try {
    const response = await axios.get('/api/subscription-details/');
    subscriptionDetails.value = response.data;
  } catch (error) {
    console.error('Error fetching subscription details:', error);
  }
};
const subscriptionStatus = computed(() => {
  if (subscriptionDetails.value) {
    return subscriptionDetails.value.status.charAt(0).toUpperCase() + subscriptionDetails.value.status.slice(1);
  } else if (user.value.is_premium) {
    return 'Premium (No active subscription)';
  } else {
    return 'Free Plan';
  }
});
const subscriptionStatusClass = computed(() => {
  switch (subscriptionStatus.value) {
    case 'Active':
    case 'Trialing':
      return 'text-green-600';
    case 'Cancelled':
      return 'text-red-600';
    default:
      return 'text-blue-600';
  }
});
const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'GBP', // Assuming USD, change if needed
  }).format(amount / 100); // Stripe amounts are in cents
};

const formatDate = (dateValue) => {
  let date;
  if (typeof dateValue === 'number') {
    // If it's a Unix timestamp (seconds since epoch)
    date = new Date(dateValue * 1000);
  } else {
    // If it's already a date string
    date = new Date(dateValue);
  }
  
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  });
};

const manageSubscription = async () => {
  try {
    const response = await axios.post('/api/create-customer-portal-session/');
    window.location.href = response.data.url;
  } catch (error) {
    console.error('Error creating customer portal session:', error);
  }
};
</script>